import React from "react"
import loadable from "@loadable/component"
import NavigationNoAuth from "./navigationNoAuth"
import AuthUserContext from "../../components/session/context"
import NavigationAuth from "./navigationAuth"
import { ToastProvider, useToasts } from "react-toast-notifications"
// const AuthUserContext = loadable(() =>
//   import("../../components/session/context")
// )
// const NavigationAuth = loadable(() => import("./navigationAuth"))
// const NavigationNoAuth = loadable(() => import("./navigationNoAuth"))
export default function Navigation() {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          <ToastProvider>
            <NavigationAuth authUser={authUser} />
          </ToastProvider>
        ) : (
          <NavigationNoAuth />
        )
      }
    </AuthUserContext.Consumer>
  )
}
