import React from "react"
import AuthUserContext from "./context"
import { withFirebase } from "../firebase"
import { onAuthUserListener } from "../firebase/firebase"
const WithAuthentication = Component => {
  class AuthenticationComponent extends React.Component {
    _initFirebase = false

    constructor(props) {
      super(props)

      this.state = {
        authUser: null,
      }
    }

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true

        this.listener = onAuthUserListener(
          authUser => {
            localStorage.setItem("authUser", JSON.stringify(authUser))
            this.setState({ authUser })
            //navigate(ROUTES.HOME)
          },
          () => {
            localStorage.removeItem("authUser")
            this.setState({ authUser: null })
          }
        )
      }
    }

    componentDidMount() {
      this.setState({
        authUser: JSON.parse(localStorage.getItem("authUser")),
      })
      this.firebaseInit()
    }

    componentDidUpdate() {
      this.firebaseInit()
    }

    componentWillUnmount() {
      this.listener && this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }
  return withFirebase(AuthenticationComponent)
}

export default WithAuthentication
