import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { version } from "./../../../package.json"
import logoIcon from "./../../images/logoIcon.png"
import "./footer.scss"
const Footer = () => {
  const location = useLocation()
  const [inApp, setInApp] = useState(false)
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  useEffect(() => {
    console.log({ location })
    if (location) {
      //Do now show footer in /app/
      if (
        location.pathname.startsWith("/app/") &&
        !location.pathname.includes("sampletravelplan")
      ) {
        setInApp(true)
      } else {
        setInApp(false)
      }
    }
  }, [])
  return (
    <>
      {!inApp && (
        <footer
          className="global-footer gradient"
          style={{ marginBottom: "60px" }}
        >
          <div className="global-footer-holder">
            <div className="global-footer-main">
              <nav className="global-footer-main-left">
                <div
                  style={{}}
                  className="logo-footer"
                  onClick={() => {
                    // if (authContext) navigate("/app/dashboard")
                    // else navigate("/")
                    navigate("/")
                  }}
                >
                  <LazyLoadImage
                    src={logoIcon}
                    alt="voyayge logo icon"
                    className=""
                    width="128px"
                    height="128px"
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      // maxWidth: "200px",
                      marginTop: "7px",
                      // marginLeft: "7rem",
                      display: "none",
                    }}
                  >
                    Go see the world,<br></br> Go{" "}
                    <strong
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      voyayge
                    </strong>
                  </p>
                </div>
              </nav>
              <div className="line-vertical"></div>
              <nav className="global-footer-main-right">
                <section>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>Company</p>
                  <ul>
                    <li>
                      <a
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        href="/about"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        href="/about#commitments"
                      >
                        Our Commitments
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        href="/contact"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/privacy"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Terms
                      </Link>
                    </li>
                  </ul>
                </section>
                <section>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    Travelers
                  </p>
                  <ul>
                    {/* <li>
                      <Link
                        to="/plan-your-trip"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Free Travel Planner App
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/#our-approach"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Our approach
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/travel-designers"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Meet Our Designers
                      </Link>
                    </li>

                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/sample-travel-plan"
                      >
                        View Sample Travel Plan
                      </Link>
                    </li>

                    <li>
                      <a
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        href="https://blog.voyayge.com"
                      >
                        Travel Inspiration
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/#our-approach"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/#faq"
                      >
                        FAQs
                      </Link>
                    </li>
                    {/* <li>
                  <Link  style={{ fontSize: "14px", fontWeight: "400" }} to="#">Find a trip designer</Link>
                </li> */}
                  </ul>
                </section>
                <section>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    Trip Designers
                  </p>
                  <ul>
                    <li>
                      <Link
                        to="/become-a-trip-designer#become-designer"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Become a designer
                      </Link>
                    </li>
                    <li>
                      <a
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        onClick={e => {
                          window.open("https://community.voyayge.com", "_blank")
                        }}
                      >
                        Designer community
                      </a>
                    </li>
                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/become-a-trip-designer#designerfaq"
                      >
                        FAQs
                      </Link>
                    </li>

                    {/* <li>
                  <Link  style={{ fontSize: "14px", fontWeight: "400" }} to="#">Find a trip designer</Link>
                </li> */}
                  </ul>
                </section>
                {/* <section>
                  <p style={{ fontSize: "18px", fontWeight: "600" }}>
                    Designer
                  </p>
                  <ul>
                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/become-a-trip-designer/#howitworksdesigner"
                      >
                        How it works
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/become-a-trip-designer"
                      >
                        Become a trip designer
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        to="/become-a-trip-designer/#faqsdesigner"
                      >
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </section> */}
                <section>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    Follow Us
                  </p>
                  <ul>
                    <li>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "baseline",
                        }}
                      >
                        <p className="level-item">
                          <i
                            className="fab fa-2x fa-instagram"
                            style={{
                              color: "#fec600",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              customNavigateTo(
                                "https://www.instagram.com/govoyayge/"
                              )
                            }}
                          ></i>
                        </p>
                        <p className="level-item">
                          <i
                            className="fab fa-2x fa-facebook-square"
                            style={{
                              color: "#fec600",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              customNavigateTo(
                                "https://www.facebook.com/govoyayge-103951681458422"
                              )
                            }}
                          ></i>
                        </p>

                        <p className="level-item">
                          <i
                            className="fab fa-2x fa-linkedin"
                            style={{
                              color: "#fec600",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              customNavigateTo(
                                "https://www.linkedin.com/company/voyay-ge-inc"
                              )
                            }}
                          ></i>
                        </p>
                        <p
                          className="level-item"
                          style={{ color: "#fec600", padding: "0px" }}
                        >
                          <i
                            className="fab fa-2x fa-twitter-square"
                            style={{
                              color: "#fec600",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              customNavigateTo("https://twitter.com/voyayge")
                            }}
                          ></i>
                        </p>
                        {/* <p className="level-item">
                          <i
                            className="fab fa-2x fa-facebook-square"
                            style={{ color: "#fec600", padding: "10px" }}
                          ></i>
                        </p> */}
                      </div>
                    </li>

                    <li>
                      <p
                        className="navbar-item"
                        style={{ fontSize: "8px", textAlign: "center" }}
                      >
                        v{version}
                      </p>
                    </li>
                  </ul>
                  <Link
                    className="button is-warning"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                    to="/#newsletter-subscribe"
                  >
                    Subscribe to our newsletter{" "}
                    {/* <i
                      className="fal fa-envelope"
                      style={{ marginLeft: "5px" }}
                    ></i>{" "} */}
                  </Link>
                  <div style={{ marginTop: "12px", display: "none" }}>
                    <Link
                      className=""
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        // textTransform: "uppercase",
                        letterSpacing: "0.5px",

                        textDecorationThickness: "1px",
                      }}
                      to="/sharetheyay"
                    >
                      {/* <i
                        style={{
                          color: "#4a4a4a",
                          marginRight: "5px",
                        }}
                        className="fas fa-thumbs-up"
                      ></i> */}
                      👍 Like what we are doing?{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                        }}
                      >
                        Spread the
                        <strong
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {" "}
                          <span style={{ color: "#fec600" }}>yay!</span>
                        </strong>{" "}
                      </span>
                      {/* <i
                        className="fal fa-envelope"
                        style={{ marginLeft: "5px" }}
                      ></i>{" "} */}
                    </Link>
                  </div>
                </section>
              </nav>
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <strong style={{ marginRight: "15px" }}>
                  Made with{" "}
                  <i
                    style={{
                      color: "#ED0508",
                    }}
                    className="fas fa-heart"
                  ></i>{" "}
                  by team voyayge
                </strong>
                {"       "}
                <strong>
                  Go Blue!{" "}
                  <i
                    style={{
                      color: "#FFCB09",
                    }}
                    className="fas fa-heart"
                  ></i>{" "}
                  <i
                    style={{
                      color: "#01274C",
                    }}
                    className="fas fa-heart"
                  ></i>{" "}
                </strong>
              </p>
            </div>
            {/* <div>
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <strong>
                  Go Blue!{" "}
                  <i
                    style={{
                      color: "#FFCB09",
                    }}
                    className="fas fa-heart"
                  ></i>{" "}
                  <i
                    style={{
                      color: "#01274C",
                    }}
                    className="fas fa-heart"
                  ></i>{" "}
                </strong>
              </p>
            </div> */}
            <div>
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  voyayge
                </span>{" "}
                © 2020 ALL RIGHTS RESERVED
              </p>
            </div>
            {/* <div className="global-footer-bottom">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <p className="level-item" style={{ color: "", padding: "0px" }}>
                  <i
                    className="fab fa-2x fa-twitter-square"
                    style={{
                      ///         color: "#fec600",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      customNavigateTo("https://twitter.com/voyayge")
                    }}
                  ></i>
                </p>
                <p className="level-item">
                  <i
                    className="fab fa-2x fa-linkedin"
                    style={{
                      ///       color: "#fec600",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      customNavigateTo(
                        "https://www.linkedin.com/company/voyay-ge-inc"
                      )
                    }}
                  ></i>
                </p>

                <p className="level-item">
                  <i
                    className="fab fa-2x fa-instagram"
                    style={{
                      ///   color: "#fec600",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      customNavigateTo("https://www.instagram.com/voyayge_inc/")
                    }}
                  ></i>
                </p>
              </div>
            </div> */}
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
