import React, { useState, useEffect, useContext } from "react"
import FirebaseContext, { withFirebase } from "../firebase/context"
//import logoSVG from "./../../images/logo.png"
import logoSVG from "./../../images/voyayge-logo-beta.png"
import blog from "./../../images/voyayge-blog.png"
import * as ROUTES from "./../../constants/routes"
import { navigate } from "gatsby"
import "./navigationBar.scss"
import defaultUserImage from "./../../images/defaultUser.png"
import { AuthUserContext } from "../session"
import * as FullStory from "@fullstory/browser"
import { version } from "./../../../package.json"
import {
  GlobalStateProvider,
  useGlobalStateStore,
} from "./../../State/global-state/global-state"
import navigationTraveler from "./navigationTraveler"
import {
  isOfflineForDatabase,
  isOnlineForDatabase,
  setPresence,
  titleCase,
  updateOnAway,
} from "../../utility/general"
import notification from "../../images/notification/notification.png"
import nonotification from "../../images/notification/favicon.png"
import { ToastProvider, useToasts } from "react-toast-notifications"
// // Only on production

const NavigationAuthBase = () => {
  const [userName, setUserName] = useState("")
  const [userPhotoURL, setUserPhotoURL] = useState()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [toggleBurger, setToggleBurger] = useState(false)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [showNotificationBell, setShowNotificationBell] = useState(false)
  const [newNotifications, setNewNotifications] = useState(false)
  const [isDesigner, setIsDesigner] = useState(false)
  const [designerApproved, setDesignerApproved] = useState(false)
  const { addToast } = useToasts()
  useEffect(() => {
    console.log("%cGlobal-State", "color: #972426; font-size: 18px", {
      globalState,
    })
    var role = JSON.parse(localStorage.getItem("sessionType"))
    // console.log({ role })

    if (role) {
      // console.log(role.sessionType)

      if (role.sessionType === "designer") setIsDesigner(true)
      else setIsDesigner(false)

      if (globalDispatch)
        globalDispatch({
          type: "updateSessionType",
          data: role.sessionType,
        })
    }

    if (globalState) {
      if (globalState.adminData !== undefined) {
        if (
          globalState.adminData.designerApprovalStatus === "none" ||
          globalState.adminData.designerApprovalStatus === "pending" ||
          globalState.adminData.designerApprovalStatus === "sentBack"
        )
          setDesignerApproved(false)
        else {
          setDesignerApproved(true)
        }
      }
    }
  }, [globalState])

  // useEffect(() => {
  //   try {
  //     var role = JSON.parse(localStorage.getItem("sessionType"))
  //     if (
  //       process.env.NODE_ENV !== "development" &&
  //       role.sessionType !== "designer"
  //     ) {
  //       if (typeof window !== "undefined") {
  //         FullStory.init({ orgId: "XXWGJ", debug: false })
  //       }
  //     } else {
  //       console.log(role.sessionType)
  //       console.log("skipping fullstory init")
  //     }
  //   } catch (err) {}
  // }, [])

  useEffect(() => {
    if (showNotificationBell) {
      handleShowNotification()
    } else {
      handleClearNotification()
    }
  }, [showNotificationBell])

  useEffect(() => {
    if (newNotifications) {
      handleShowNotification()
    } else {
      handleClearNotification()
    }
  }, [newNotifications])

  const fetchAdminData = params => {
    dbContext
      .firestore()
      .collection("users")
      .doc(`${authContext.uid}`)
      .collection("adminData")
      .doc(`${authContext.uid}`)
      .get()
      .then(doc => {
        if (doc) {
          // console.log("updateAdminData: ", doc.data())
          if (doc.data()) {
            globalDispatch({
              type: "updateAdminData",
              data: doc.data(),
            })
          } else {
            // TODO: To cover the issue where the use only singned up as a traveler but logins' as a designer. Need to find a better way to fix this.
            globalDispatch({
              type: "updateAdminData",
              data: { designerApprovalStatus: "none" },
            })
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  function getFaviconEl() {
    if (typeof window !== "undefined") {
      return document.getElementById("favicon")
    }
  }
  const handleShowNotification = () => {
    const favicon = getFaviconEl() // Accessing favicon element
    if (favicon) favicon.setAttribute("href", `${notification}`)
  }
  const handleClearNotification = () => {
    const favicon = getFaviconEl() // Accessing favicon element
    if (favicon) favicon.setAttribute("href", `${nonotification}`)
  }

  useEffect(() => {
    try {
      if (authContext) {
        if (globalState) {
          if (globalState.status !== "online") {
            // this condition is important to make sure repeated writes are not made to DB
            setPresence(authContext.uid, isOnlineForDatabase)
            updateOnAway(authContext.uid)
            if (globalDispatch) {
              globalDispatch({
                type: "updatePresence",
                data: "online",
              })
            }
          }
        }
        // let name = authContext.displayName
        //   ? authContext.displayName
        //   : globalState.currentUserData
        //   ? globalState.currentUserData.firstName
        //   : ""
        // console.log({ name })
        // window.Intercom("boot", {
        //   app_id: "est4hrpg",
        //   email: `${authContext.email}`,
        //   name: `${name}`,
        //   createdAt: `${authContext.createdAt}`,
        //   uid: `${authContext.uid}`,
        //   type: `${globalState.currentSessionRole}`,
        //   user_id: `${authContext.uid}`,
        // })
        // console.log({ authContext })
      }
    } catch (err) {
      console.log(err)
    }
  }, [authContext])

  useEffect(() => {
    //const userData = authUser.providerData[0]
    try {
      var unsubscribeUser = ""
      var unsubscribeNotifications = ""
      if (dbContext) {
        // console.log("AuthContext" + authContext.uid)
        unsubscribeUser = dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .onSnapshot(
            doc => {
              if (doc) {
                let data = doc.data()
                console.log({ doc })
                console.log("NavigationAuthBase: " + data)
                try {
                  if (data.username === "") {
                    setUserName(
                      titleCase(data.firstName) + " " + titleCase(data.lastName)
                    )
                  } else {
                    setUserName(data.username)
                  }

                  setUserPhotoURL(data.userProfileImage)
                  globalDispatch({
                    type: "updateCurrentUserDetails",
                    data: {
                      firstName: data.firstName,
                      lastName: data.lastName,
                      userName: data.username,
                      userProfileImage: data.userProfileImage,
                    },
                  })
                  if (data.npsComplete) {
                    globalDispatch({
                      type: "updateNPSState",
                      data: true,
                    })
                  } else {
                    globalDispatch({
                      type: "updateNPSState",
                      data: false,
                    })
                  }
                } catch (e) {
                  console.log(e)
                }
              }
            },
            err => {
              console.log(err)
            }
          )
        unsubscribeNotifications = dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("publicNotifications")
          .doc(`${authContext.uid}`)
          .onSnapshot(function(doc) {
            console.log("Current data: ", doc.data())
            if (doc.data() !== undefined) {
              setShowNotificationBell(doc.data().showNewMessage)
              setNewNotifications(doc.data().showNewNotification)
              // globalDispatch({
              //   type: "updateNewMessageAvailable",
              //   data: doc.data().showNewMessage,
              // })
              // globalDispatch({
              //   type: "updateNewNotificationAvailable",
              //   data: doc.data().showNewNotification,
              // })
            }
          })

        // if (globalState.currentSessionRole === "") {
        if (!localStorage.getItem("sessionType")) {
          console.log("fetching session role from cloud")
          dbContext
            .firestore()
            .collection("users")
            .doc(`${authContext.uid}`)
            .get()
            .then(doc => {
              if (doc) {
                var role = doc.data().currentSessionRole
                console.log({ role })
                if (role === "traveler" || role === "designer") {
                  globalDispatch({
                    type: "updateSessionType",
                    data: role,
                  })
                  fetchAdminData()
                }
              }
            })
            .catch(e => console.log(e))
        } else {
          // console.log(localStorage.getItem("sessionType"))
          fetchAdminData()
        }
      }
      return () => {
        if (unsubscribeUser) unsubscribeUser()
        if (unsubscribeNotifications) unsubscribeNotifications()
      }
    } catch (err) {
      console.log(err)
    }
    //console.log({ authUser })
    //const { displayName, photoURL } = userData

    // if (displayName) {
    //   setUserName(displayName)
    //   setShortHandDisplayName(
    //     authUser.providerData[0].displayName
    //       .split(" ")
    //       .map(x => x[0])
    //       .join("")
    //       .toUpperCase()
    //   )
    // } else {
    //   setUserName(userData.username)
    // }
    // dbGet(dbContext, "users", authUser.uid)
    //   .then(doc => {
    //     if (doc.exists) {
    //       const _userProfileImage = getSafe(doc.data().userProfileImage, "")
    //       if (_userProfileImage !== null || _userProfileImage !== "") {
    //         setUserPhotoURL(_userProfileImage)
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     console.log("Error getting document:", error)
    //   })
    // if (photoURL) {
    //   setUserPhotoURL(authUser.providerData[0].photoURL)
  }, [])

  useEffect(() => {
    if (userName !== "") {
      try {
        console.log("userName ->", userName)
        window.Intercom("boot", {
          app_id: "est4hrpg",
          email: `${authContext.email}`,
          name: `${userName}`,
          createdAt: `${authContext.createdAt}`,
          uid: `${authContext.uid}`,
          type: `${globalState.currentSessionRole}`,
          user_id: `${authContext.uid}`,
        })
      } catch (err) {}
    }
  }, [userName])

  const signOut = () => {
    setToggleBurger(!toggleBurger)
    localStorage.removeItem("sessionType")
    localStorage.removeItem("redirectParameters")
    localStorage.removeItem("firstSession")

    window.Intercom("shutdown")

    if (dbContext) {
      dbContext
        .auth()
        .signOut()
        .then(async function() {
          await setPresence(authContext.uid, isOfflineForDatabase)
          navigate(ROUTES.LANDING)
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  const displayRightMenuItemForDesigner = () => {
    if (globalState.adminData !== undefined) {
      if (
        globalState.adminData.designerApprovalStatus === "none" ||
        globalState.adminData.designerApprovalStatus === "pending" ||
        globalState.adminData.designerApprovalStatus === "sentBack"
      )
        return "Designer Application"
      else {
        return "Jobs"
      }
    }
  }

  const displayActivitiesForDesigner = () => {
    if (globalState.adminData !== undefined) {
      if (
        globalState.adminData.designerApprovalStatus === "none" ||
        globalState.adminData.designerApprovalStatus === "pending" ||
        globalState.adminData.designerApprovalStatus === "sentBack"
      )
        return null
      else {
        return "Activities"
      }
    }
  }
  const displayFreeTravelAppForDesigner = () => {
    if (globalState.adminData !== undefined) {
      if (
        globalState.adminData.designerApprovalStatus === "none" ||
        globalState.adminData.designerApprovalStatus === "pending" ||
        globalState.adminData.designerApprovalStatus === "sentBack"
      )
        return null
      else {
        return "Travel Planner"
      }
    }
  }

  // const displayName = () => {
  //   try {
  //     if(userName === "")

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  const displayRightMenuItemForTraveler = () => {
    if (globalState.adminData)
      switch (globalState.adminData.designerApprovalStatus) {
        case "pending":
          return (
            <a
              className="navbar-item hover-link"
              onClick={e => {
                navigate(ROUTES.DESIGNER_SIGNUP)
              }}
            >
              My Designer Application
            </a>
          )
          break
        case "approved":
          return ""
        default:
          return (
            <a
              className="navbar-item hover-link"
              onClick={e => {
                // navigate(ROUTES.BECOME_A_DESIGNER)
                navigate(ROUTES.DESIGNER_SIGNUP)
              }}
            >
              Become a designer
            </a>
          )
          break
      }
  }
  const clearNotificationFlag = () => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("publicNotifications")
          .doc(`${authContext.uid}`)
          .update({ showNewMessage: false })
          .catch(err => {})
    } catch (err) {
      console.log(err)
    }
  }
  const clearActiveNotificationFlag = () => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("publicNotifications")
          .doc(`${authContext.uid}`)
          .update({ showNewNotification: false })
          .catch(err => {})
    } catch (err) {
      console.log(err)
    }
  }
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }

  const showOnline = () => {
    try {
      if (globalState) {
        if (globalState.status === "online") {
          return <span className="online"></span>
        } else return ""
      }
    } catch (err) {
      return ""
    }
  }
  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div style={{ width: "120px" }}>
            <a
              className="navbar-item"
              onClick={() => {
                navigate("/")
                // if (authContext) navigate("/app/dashboard")
                // else navigate("/")
              }}
            >
              <img
                // className="is-marginless"
                style={{ marginTop: "10px" }}
                src={logoSVG}
                width="170"
                height="70"
              />
            </a>
          </div>
          <a
            name="burger-button"
            role="button"
            className={`navbar-burger burger  ${
              toggleBurger ? `is-active` : ""
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={e => {
              setToggleBurger(!toggleBurger)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={`navbar-menu ${toggleBurger ? `is-active` : ""}`}
          style={
            {
              // width: "100vw",
              // height: "100vh",
              // padding: "20px",
              // margin: "0px",
              // zIndex: "99",
              // backgroundColor: "#fec600",
            }
          }
        >
          {/* <navigationTraveler
            signOut={signOut}
            setToggleBurger={setToggleBurger}
            toggleBurger={toggleBurger}
          /> */}
          <div className="navbar-start"></div>

          <div className="navbar-end">
            {/* <a
              className="navbar-item hover-link"
              onClick={e => {
                setToggleBurger(!toggleBurger)
                navigate(ROUTES.LANDING)
              }}
            >
              Home
            </a> */}
            {/* <div className="navbar-item">
<div className="buttons">
<a onClick={signOut} className="button is-primary">
  <strong>Sign Out</strong>
</a>
</div>
</div> */}
            {/* <div className="navbar-item">Messages</div> */}
            {/* For traveller initially show "Become a designer" and once the application process has started show "Designer application" */}
            {isDesigner ? "" : displayRightMenuItemForTraveler()}
            {isDesigner ? (
              ""
            ) : (
              <a
                className="navbar-item hover-link"
                onClick={e => {
                  setToggleBurger(!toggleBurger)
                  navigate(ROUTES.TRAVEL_DESIGNER_APP)
                }}
              >
                {/* <i className="far fa-search" style={{ marginRight: "5px" }}></i>{" "} */}
                Meet Our Travel Designers
                {/* <i className="far fa-search" style={{ marginRight: "5px" }}></i>{" "}
                Search Travel Designers */}
              </a>
            )}

            {isDesigner ? (
              <a
                className="navbar-item hover-link"
                onClick={e => {
                  setToggleBurger(!toggleBurger)
                  navigate(ROUTES.DASHBOARD)
                }}
              >
                {displayRightMenuItemForDesigner()}
              </a>
            ) : (
              <a
                className="navbar-item hover-link"
                onClick={e => {
                  setToggleBurger(!toggleBurger)
                  navigate(ROUTES.DASHBOARD)
                }}
              >
                My Trips
              </a>
            )}
            {isDesigner && designerApproved ? (
              <a
                className="navbar-item hover-link"
                onClick={e => {
                  setToggleBurger(!toggleBurger)
                  navigate(ROUTES.ACTIVITIES)
                }}
              >
                {displayActivitiesForDesigner()}
              </a>
            ) : (
              ""
            )}
            {isDesigner && designerApproved ? (
              <a
                className="navbar-item hover-link"
                onClick={e => {
                  setToggleBurger(!toggleBurger)
                  addToast(
                    `Please sign-in as a traveler to access your travel planner.`,
                    {
                      appearance: "warning",
                      autoDismiss: true,
                    }
                  )
                }}
              >
                {displayFreeTravelAppForDesigner()}
              </a>
            ) : (
              ""
            )}
            <a
              className="navbar-item hover-link"
              onClick={e => {
                setNewNotifications(false)
                clearActiveNotificationFlag()
                setToggleBurger(!toggleBurger)
                navigate(ROUTES.NOTIFICATIONS)
              }}
            >
              {newNotifications ? (
                <i className="far fa-bell-on fa-lg">
                  <div className="blob-notification red"></div>
                </i>
              ) : (
                <i className="far fa-bell fa-lg"></i>
              )}
            </a>

            <a
              className="navbar-item hover-link"
              onClick={e => {
                setShowNotificationBell(false)
                clearNotificationFlag()
                setToggleBurger(!toggleBurger)
                navigate(ROUTES.MESSAGES)
              }}
            >
              <i className="far fa-envelope fa-lg">
                {showNotificationBell ? (
                  <div className="blob-message red"></div>
                ) : (
                  ""
                )}
              </i>
            </a>

            {/* <div className="navbar-item has-dropdown is-hoverable">
<a className="navbar-link hover-link">Messages</a>
<div className="navbar-dropdown">
<a className="navbar-item">About</a>
<a className="navbar-item">Jobs</a>
<a className="navbar-item">Contact</a>
<hr className="navbar-divider"></hr>
<a className="navbar-item">Report an issue</a>
</div>
</div> */}

            <a
              onClick={e => {
                setToggleBurger(!toggleBurger)

                customNavigateTo("https://blog.voyayge.com")
              }}
              className="navbar-item hover-link"
            >
              {/* <img src={blog} style={{ width: "150px" }} alt="blog-logo" /> */}
              Blog
            </a>
            {isDesigner && (
              <a
                onClick={e => {
                  setToggleBurger(!toggleBurger)

                  customNavigateTo("https://community.voyayge.com")
                }}
                className="navbar-item hover-link"
              >
                {/* <img src={blog} style={{ width: "150px" }} alt="blog-logo" /> */}
                Community
              </a>
            )}
            <div className="verticalLine"></div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link hover-link">
                <div className="nav-avatar">
                  <div className="circle">
                    <img
                      className="is-rounded-avatar"
                      src={userPhotoURL ? userPhotoURL : defaultUserImage}
                    />
                    {showOnline()}
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ textAlign: "left", lineHeight: "50px" }}>
                      {titleCase(userName)}
                    </p>
                  </div>
                </div>
                {/* {shortHandDisplayName} */}
              </a>
              <div className="navbar-dropdown is-boxed">
                {/* <a
                  className="navbar-item hover-link"
                  onClick={() => {
                    setToggleBurger(!toggleBurger)

                    navigate(ROUTES.DASHBOARD)
                  }}
                >
                  Dashboard
                </a> */}
                {/* <hr className="navbar-divider"></hr> */}
                <a
                  className="navbar-item hover-link"
                  // style={{ fontSize: "16px" }}
                  onClick={() => {
                    setToggleBurger(!toggleBurger)
                    navigate(ROUTES.PROFILE)
                  }}
                >
                  Profile
                </a>
                <hr className="navbar-divider"></hr>
                <a
                  className="navbar-item hover-link"
                  // style={{ fontSize: "16px" }}
                  onClick={() => {
                    setToggleBurger(!toggleBurger)
                    navigate(ROUTES.ACCOUNT)
                  }}
                >
                  Account
                </a>
                <hr className="navbar-divider"></hr>
                <a
                  className="navbar-item hover-link"
                  // style={{ fontSize: "16px" }}
                  onClick={() => {
                    setToggleBurger(!toggleBurger)
                    navigate(ROUTES.GENERAL_FEEDBACK)
                  }}
                >
                  Feedback
                </a>
                <hr className="navbar-divider"></hr>
                <a
                  className="navbar-item hover-link"
                  // style={{ fontSize: "16px" }}
                  onClick={() => {
                    setToggleBurger(!toggleBurger)
                    navigate(ROUTES.SUPPORT)
                  }}
                >
                  Support
                </a>
                <hr className="navbar-divider"></hr>
                <button
                  className="navbar-item button is-warning"
                  onClick={signOut}
                  style={{
                    width: "100%",
                    // margin: "0 12px",
                    fontSize: "14px",
                    textAlign: "center",
                    borderRadius: "0px",
                    lineHeight: "14px",
                    // padding: "0px 12px",
                  }}
                >
                  Sign out
                  {/* <p
                    className=""
                    style={{ margin: "auto 0", textAlign: "center" }}
                  >
                    Sign out
                  </p> */}
                </button>
                <hr className="navbar-divider"></hr>
                <p
                  className="navbar-item"
                  style={{ fontSize: "10px", textAlign: "right" }}
                >
                  Build Version: {version}
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default withFirebase(NavigationAuthBase)
