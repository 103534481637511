import React from "react"
import defaultUserImage from "../images/defaultUser.png"
import { auth, dbRtl } from "../components/firebase/firebase"
import firebase from "../components/firebase/firebase"
import countryCode from "../constants/data/general"
export function getSafe(value, defaultVal) {
  try {
    return value
  } catch (e) {
    return defaultVal
  }
}
const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
}

export const removeSlug = text => {
  try {
    // return text.replace("-", " ")
    return text.replace(/-/g, " ")
  } catch (err) {}
}

export const shuffle = unshuffled => {
  try {
    let shuffled = unshuffled
      .map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value)
    return shuffled
  } catch (err) {
    return unshuffled
  }
}
export const calculateProgress = (date, daysDue) => {
  try {
    if (date) {
      var a = date.toDate()
      var today = new Date()
      var totalDays = Math.round(
        (a.setDate(a.getDate() + daysDue) - today) / (24 * 60 * 60 * 1000)
      )
      // setElapsedDays(totalDays)
      var percent = Math.round((totalDays / daysDue) * 100)
      return percent
    } else return 0
  } catch (err) {
    return 0
  }
}
export const daysRemaining = (date, daysDue) => {
  try {
    if (date) {
      var a = date.toDate()
      var today = new Date()
      return Math.round(
        (a.setDate(a.getDate() + daysDue) - today) / (24 * 60 * 60 * 1000)
      )
    } else return 0
  } catch (err) {
    return 0
  }
}

export const getSafeAddDays = (d, days) => {
  try {
    var newDate = d.toDate().getTime() + days * 60 * 60 * 24 * 1000
    return new Intl.DateTimeFormat("en-US", options).format(new Date(newDate))
  } catch (error) {
    var newDate = d.getTime() + days * 60 * 60 * 24 * 1000
    return new Intl.DateTimeFormat("en-US", options).format(new Date(newDate))
  }
}

export function titleCase(str) {
  try {
    str = str.toLowerCase().split(" ")
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(" ")
  } catch (error) {
    console.log({ error })
    return "--"
  }
}

export function blobToDataURL(blob, callback) {
  var a = new FileReader()
  a.onload = function(e) {
    callback(e.target.result)
  }
  a.readAsDataURL(blob)
}
// splitStringCombos("Le Cirq") -> "L", "Le", "Le " , "Le C", "Le Ci", "Le Cir", "Le Cirq", "C", "Ci", "Cir", "Cirq"
export const splitStringCombos = data => {
  try {
    var a = []
    var max = data.length
    console.log({ max })
    for (let i = 1; i <= data.length; i++) {
      a.push(data.substring(0, i).toLowerCase())
    }
    var b = data.split(" ")
    console.log({ b })

    for (let i = 1; i < b.length; i++) {
      for (let k = 1; k <= b[i].length; k++) {
        a.push(b[i].substring(0, k).toLowerCase())
      }
    }
    return a
  } catch (err) {
    console.log(err)
  }
}
export const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const googleDirectionsURI = (
  sourceName,
  sourceAddress,
  destinationName,
  destinationAddress,
  mode
) => {
  try {
    var encodedSource = encodeURI(sourceName) + "%20" + encodeURI(sourceAddress)
    var encodedDestination =
      encodeURI(destinationName) + "%20" + encodeURI(destinationAddress)
    var encodedTravelMode = encodeURI(mode)
    let finalURI = `https://www.google.com/maps/dir/?api=1&origin=${encodedSource}&destination=${encodedDestination}&travelmode=${encodedTravelMode}`
    // console.log({ finalURI })
    return finalURI
  } catch (error) {
    console.log(error)
    return ""
  }
}

export const googleMapsURI = address => {
  if (address) {
    var res = encodeURI(address)
    return `https://www.google.com/maps/search/?api=1&query=${res}`
  }
}

export const dottedText = (text, characterLength) => {
  try {
    return text.length > characterLength
      ? text.substring(0, characterLength - 1) + " ..."
      : text
  } catch (error) {
    console.log(text, error)
    return "--"
  }
}
export const dottedFileName = (text, characterLength) => {
  try {
    const regex = /......[0-9a-z]+$/gm

    const str = text
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        // console.log(`Found match: ${match}`)
        ext = match
      })
    }
    // console.log({ ext })
    return text.length > characterLength
      ? text.substring(0, characterLength - 8) + " ..." + ext
      : text
  } catch (error) {
    console.log(text, error)
    return "--"
  }
}
export const getFileExtension = fileName => {
  try {
    const regex = /.[0-9a-z]+$/gm

    const str = fileName
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`)
        ext = match
      })
    }
    return ext
  } catch (error) {
    console.log(fileName, error)
    return "--"
  }
}
export const addZeroBefore = n => {
  return (n < 10 ? "0" : "") + n
}

export const getHours = t => {
  try {
    return typeof t === "string" ? new Date(t).getUTCHours() : t.getUTCHours()
  } catch (error) {
    return t.toDate().getUTCHours()
  }
}

export const getMinutes = t => {
  try {
    return typeof t === "string"
      ? new Date(t).getUTCMinutes()
      : t.getUTCMinutes()
  } catch (error) {
    return t.toDate().getUTCMinutes()
  }
}

export const parseDayAndItemFromString = inputString => {
  try {
    //const regex = /Day:(\d*)-Item:(\d*)/gm
    const regex = /Day:(\d*)-Item:([0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12})/gm
    let m = regex.exec(inputString)
    var z = []
    m.forEach((match, groupIndex) => {
      console.log(`Found match, group ${groupIndex}: ${match}`)
      z.push(match)
    })
    return z
  } catch (err) {
    console.log(err)
    return []
  }
}

/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key
 * @param {String} value The localStorage() value object value
 */
export var addToLocalStorageObject = function(name, key, value) {
  console.log(`name: ${name} key:${key} value:${value}`)
  // Get the existing data
  var existing = localStorage.getItem(name)
  console.log("existing: ", existing)
  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {}

  // Add new data to localStorage Array
  existing[key] = value

  // Save back to localStorage
  localStorage.setItem(name, JSON.stringify(existing))
}

/**
 * Wraps a JSX  or String in a responsive BULMA column
 * @param {String} name  The localStorage() key
 * @param {JSX} key   A JSX or string that needs to be wrapped in the responsive BULMA column
 */
export const responsiveColumnWrap = item => {
  return (
    <div className="columns is-mobile is-vcentered">
      <div className="column is-3-desktop is-2-tablet is-1-mobile"></div>
      <div className="column is-6-desktop is-8-tablet is-10-mobile">{item}</div>
      <div className="column is-3-desktop is-2-tablet is-1-mobile"></div>
    </div>
  )
}
export const responsiveColumnWrapWide = item => {
  return (
    <div className="columns is-mobile is-vcentered">
      <div className="column is-1-desktop is-1-tablet is-1-mobile"></div>
      <div className="column is-10-desktop is-10-tablet is-10-mobile">
        {item}
      </div>
      <div className="column is-1-desktop is-1-tablet is-1-mobile"></div>
    </div>
  )
}

export const renderProfileImage = imageSrc => {
  try {
    return (
      <img
        className=""
        style={{ borderRadius: "5px" }}
        src={imageSrc}
        alt="designer profile"
      />
    )
  } catch (error) {
    return (
      <img
        className="is-rounded"
        src={defaultUserImage}
        alt="designer profile"
      />
    )
  }
}

export const daysBetweenDates = (startDate, endDate) => {
  try {
    var totalTravelDays = Math.round(
      (endDate - startDate) / (24 * 60 * 60 * 1000)
    )
    return totalTravelDays
  } catch (err) {
    console.log(err)
    return 0
  }
}

function isJsonString(str) {
  try {
    console.log(str)
    JSON.parse(str)
    console.log(str.json())
  } catch (e) {
    return false
  }
  return true
}

export async function fetchFromAPI(verb, endpointURL, opts, token) {
  try {
    const { method, body } = { method: verb, body: null, ...opts }
    //const user = auth.currentUser
    console.log({ body })
    // const token = user && (await user.getIdToken())
    //
    console.log({ token })
    let API = process.env.CLOUD_FUNCTIONS_ENDPOINT
    console.log("Using API: ", API)
    const res = await fetch(`${API}/${endpointURL}`, {
      method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        //Accept: "application/json",
      },
    })
    return res.json()
    // if (isJsonString(res)) return res.json()
    // else return res
  } catch (err) {
    console.log({ err })
    return "error"
  }
}

export var isOfflineForDatabase = {
  state: "offline",
  last_changed: firebase.database.ServerValue.TIMESTAMP,
}

export var isOnlineForDatabase = {
  state: "online",
  last_changed: firebase.database.ServerValue.TIMESTAMP,
}
export var isAwayForDatabase = {
  state: "away",
  last_changed: firebase.database.ServerValue.TIMESTAMP,
}
export function getPresence(uid) {
  dbRtl
    .ref("/status/" + uid)
    .once("value")
    .then(snapshot => {
      console.log(snapshot.val())
      return snapshot.val().state || "offline"
    })
}

export async function setPresence(uid, status) {
  var userStatusDatabaseRef = dbRtl.ref("/status/" + uid)
  await userStatusDatabaseRef.set(status)
}

export function updateOnAway(uid) {
  if (typeof window !== "undefined") {
    document.onvisibilitychange = e => {
      if (document.visibilityState === "hidden") {
        setPresence(uid, isAwayForDatabase)
      } else {
        setPresence(uid, isOnlineForDatabase)
      }
    }
  }
}

export const getCodes = data => {
  try {
    let codes = []
    if (data) {
      const a = data
      a.map((item, index) => {
        console.log(item)
        const _foundItem = countryCode.find(
          element => element.Name === item.label.trim()
        )
        console.log(_foundItem)
        if (_foundItem !== -1) codes = { ...codes, ...{ [_foundItem.Code]: 0 } }
      })
      console.log({ codes })
      return codes
    } else return
  } catch (err) {
    console.log(err)
  }
}

export const getLabelValue = data => {
  try {
    return data
      ? data.label !== ""
        ? data.label
        : "- Not Specified -"
      : "- Not Specified -"
  } catch (e) {
    return "--"
  }
}
