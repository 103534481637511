import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import * as ROUTES from "./../../constants/routes"
import logoSVG from "./../../images/voyayge-logo-beta.png"
//import logoSVG from "./../../images/logo.png"

const NavigationNoAuth = () => {
  const [toggleBurger, setToggleBurger] = useState(false)
  useEffect(() => {
    window.Intercom("boot", {
      app_id: "est4hrpg",
    })
  }, [])
  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <div style={{ width: "120px" }}>
            <a
              className="navbar-item"
              onClick={() => {
                navigate("/")
              }}
            >
              <img
                // className="is-marginless"
                src={logoSVG}
                width="150"
                height="42"
                alt="Company Logo"
                style={{ marginTop: "10px" }}
              />
            </a>
          </div>
          <a
            role="button"
            className={`navbar-burger burger  ${
              toggleBurger ? `is-active` : ""
            }`}
            aria-label="main navigation"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={e => {
              setToggleBurger(!toggleBurger)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={`navbar-menu ${toggleBurger ? `is-active` : ""}`}
        >
          <div className="navbar-start">
            {/* <Link className="navbar-item hover-link" to="/#howitworkstraveler">
              Our Approach
            </Link>
            <Link className="navbar-item hover-link" to="/#pricing">
              Pricing
            </Link>
            <a className="navbar-item hover-link">Travel inspiration</a>
            <a
              className="navbar-item hover-link"
              onClick={() => {
                navigate(ROUTES.BECOME_A_DESIGNER)
              }}
            >
              Become a trip designer
            </a>
            <Link className="navbar-item hover-link" to="/about">
              About us
            </Link> */}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <Link
                style={{ marginRight: "20px" }}
                to="/"
                className="navbar-item hover-link"
              >
                Home
              </Link>
              {/* <Link
                style={{ marginRight: "20px" }}
                to="/plan-your-trip"
                className="navbar-item hover-link"
              >
                Free Travel Planner App
              </Link> */}
              {/* <Link
                style={{ marginRight: "20px" }}
                to="/#our-approach"
                className="navbar-item hover-link"
              >
                Our Approach
              </Link> */}

              <Link
                style={{ marginRight: "20px" }}
                to="/travel-designers"
                className="navbar-item hover-link"
              >
                Meet Our Travel Designers
                {/* <i className="far fa-search" style={{ marginRight: "5px" }}></i>{" "}
                Search Travel Designers */}
              </Link>
              <Link
                style={{ marginRight: "20px" }}
                to="/become-a-trip-designer"
                className="navbar-item hover-link"
              >
                Become a Travel Designer
              </Link>
              <a
                style={{ marginRight: "20px" }}
                href="https://blog.voyayge.com"
                className="navbar-item hover-link"
              >
                Blog
              </a>
              {/* <a
                style={{ marginRight: "20px" }}
                onClick={e => {
                  window.open("https://community.voyayge.com", "_blank")
                }}
                className="navbar-item hover-link"
              >
                Designer Community
              </a> */}
              <a
                style={{ marginRight: "20px" }}
                href="/about"
                className="navbar-item hover-link"
              >
                About us
              </a>
              <div className="buttons navbar-item">
                <Link
                  style={{
                    marginRight: "20px",
                    fontSize: "14px",
                    height: "40px",
                  }}
                  className="button is-warning brand-shadow width100 marginTop10-mobile"
                  to={ROUTES.SIGN_UP}
                >
                  Sign up
                </Link>
                <a
                  className="button is-warning is-inverted brand-shadow width100 marginTop10-mobile"
                  style={{
                    marginRight: "0px",
                    fontSize: "14px",
                    height: "40px",
                  }}
                  onClick={e => {
                    setToggleBurger(!toggleBurger)
                    navigate(ROUTES.SIGN_IN)
                  }}
                >
                  Sign in
                </a>
                {/* <Link className="button is-light" to={SIGN_IN}>
                  Sign in
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavigationNoAuth
