export const LANDING = "/"
export const SIGN_UP = "/signup"
// export const SIGN_UP = "/signup-as-designer"
export const SIGN_IN = "/signin"
export const HOME = "/app/home"

export const PASSWORD_FORGET = "/pw-forget"
export const ADMIN = "/admin"
export const PROFILE = "/app/profile"
export const ADMIN_DETAILS = "/admin/:id"
export const DASHBOARD = "/app/dashboard"
export const ACTIVITIES = "/app/activities"
export const NEWTRIP = "/app/tripwizard"
export const PLANYOURTRIP = "/app/planyourtrip"
export const TRIPDETAILS = "/app/tripdetails"
export const MESSAGES = "/app/messages"
export const NOTIFICATIONS = "/app/notifications"
export const ACCOUNT = "/app/account"
export const BECOME_A_DESIGNER = "/become-a-trip-designer"
export const EDIT_PHOTOS = "/app/uploadphotos"
export const THANKYOU = "/app/thankyou"
export const DESIGNER_SIGNUP = "/app/designersignup"
export const SIGNUP_AS_DESIGNER = "/signup-as-designer"
export const DESIGNER_INVITE = "/request-designer-invite"
export const TRAVELER_DETAILS = "/app/tripdetails"
export const BYOT_TRAVELER_DETAILS = "/app/byottripdetails"
export const JOB_DETAILS = "/app/jobdetails"
export const BLOGS = "https://blog.voyayge.com"
export const TRAVEL_DESIGNER = "/travel-designers"
export const TRAVEL_DESIGNER_APP = "/app/travel-designers"
export const SAMPLE_TRAVEL_PLAN_FORM =
  "/app/designersignup/sampletravelplanentry"
export const GENERAL_FEEDBACK = "/app/product-feedback"
export const SUPPORT = "/app/support"

//export const PENDING = "/app/pending"
