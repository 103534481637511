import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/database"
import "firebase/compat/auth"
import "firebase/compat/storage"
import "firebase/compat/analytics"
import "firebase/compat/functions"

let config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  tripDocsStorageBucket: process.env.TRIP_DOCS_STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
  databaseURL: process.env.DATABASE_URL,
}

// if (process.env.NODE_ENV === "development") {
//   config = {
//     // DEVELOPMENT - // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//     apiKey: "AIzaSyBJclkwazz9WD1q4JitHcyTNuoTBUzpr1w",
//     authDomain: "voyayge-dev-f5a48.firebaseapp.com",
//     projectId: "voyayge-dev-f5a48",
//     storageBucket: "voyayge-dev-f5a48.appspot.com",
//     messagingSenderId: "850278174447",
//     appId: "1:850278174447:web:105e938c2a8dd005ca951a",
//     measurementId: "G-B62P5JQCQR",
//     databaseURL: "https://voyayge-dev-f5a48-default-rtdb.firebaseio.com/",
//   }
// }
// if (process.env.NODE_ENV === "production") {
//   config = {
//     // PRODUCTION:
//     apiKey: "AIzaSyDNwSQjUKbyAJd5LaWXV8aKI_7yuqUlsag",
//     // authDomain: "voyayge-dev-9adfe.firebaseapp.com",
//     authDomain: "voyayge.com",
//     databaseURL: "https://voyayge-dev-9adfe.firebaseio.com",
//     projectId: "voyayge-dev-9adfe",
//     storageBucket: "voyayge-dev-9adfe.appspot.com",
//     messagingSenderId: "645682771297",
//     appId: "1:645682771297:web:eadced2b3d4927b0824120",
//     measurementId: "G-ECL9WRC9WX",
//   }
// }

var _auth,
  _db,
  _dbRtl,
  _dbStorage,
  _functions,
  _analytics = ""

if (typeof window !== "undefined") {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
    // firebase = getApps()
  }

  _auth = firebase.auth()
  _db = firebase.firestore()
  _dbRtl = firebase.database()
  _dbStorage = firebase.storage()
  _analytics = firebase.analytics()
  _functions = firebase.functions()
  var provider = new firebase.auth.GoogleAuthProvider()
  provider.setCustomParameters({ prompt: "select_account" })
}

export const functions = _functions
export const auth = _auth
export const db = _db
export const dbRtl = _dbRtl
export const dbStorage = _dbStorage
export const analytics = _analytics
// *** Merge Auth and DB User API *** //

export function onAuthUserListener(next, fallback) {
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
      // this.user(authUser.uid)
      //console.log("Inside onAuthUserListener")
      //console.log({ authUser })
      next(authUser)
      // firebase
      //   .firestore()
      //   .collection("users")
      //   .doc(`${authUser.uid}`)
      //   .get()
      //   .then(doc => {
      //     console.log("*&*&*&*&*&*&*&*")
      //     if (doc.exists) {
      //       const dbUser = doc.data()

      //       // default empty roles
      //       if (!dbUser.roles) {
      //         dbUser.roles = {}
      //       }

      //       // merge auth and db user
      //       authUser = {
      //         uid: authUser.uid,
      //         email: authUser.email,
      //         emailVerified: authUser.emailVerified,
      //         providerData: authUser.providerData,
      //         ...dbUser,
      //       }
      //     }
      // next(authUser)
      //   })
    } else {
      fallback()
    }
  })
}

export function getFirebase() {
  return firebase
}

export default firebase
