export function globalStateReducer(draft, action) {
  switch (action.type) {
    case "updateCurrentUserDetails":
      if (action.data) draft.currentUserData = action.data
      return
    case "updateSessionType":
      if (action.data) draft.currentSessionRole = action.data
      return
    case "updateAdminData":
      if (action.data) draft.adminData = action.data
      return
    case "updatePresence":
      if (action.data) draft.status = action.data
      return
    case "updateNPSState":
      if (action.data) draft.npsComplete = action.data
      return
    case "updateNewMessageAvailable":
      if (action.data) draft.newMessageAvailable = action.data
      return
    case "updateNewNotificationAvailable":
      if (action.data) draft.newNotificationAvailable = action.data
      return
    case "updateDesignerApprovalStatus":
      if (action.data) {
        if (draft.adminData === undefined) {
          draft.adminData = {}
        }
        draft.adminData.designerApprovalStatus = action.data
      }
      return
    default:
      return
  }
}
