// import adrenaline from "./../../images/badges/adrenaline.svg"
// import animal from "./../../images/badges/animal.svg"
// import art from "./../../images/badges/art.svg"
// import beer from "./../../images/badges/beer.png"
// import camping from "./../../images/badges/camping.svg"
// import climbing from "./../../images/badges/climbing.svg"
// import club from "./../../images/badges/club.png"
// import coffee from "./../../images/badges/coffee.svg"
// import family from "./../../images/badges/family.svg"
// import foodie from "./../../images/badges/foodie.svg"
// import handicap from "./../../images/badges/handicap.svg"
// import hike from "./../../images/badges/hike.svg"
// import history from "./../../images/badges/history.svg"
// import offroad from "./../../images/badges/offroad.svg"
// import solo from "./../../images/badges/solo.svg"
// import sports from "./../../images/badges/sport.svg"
// import water from "./../../images/badges/water.svg"
// import whiskey from "./../../images/badges/whiskey.svg"
// import wine from "./../../images/badges/wine.svg"
// import winter from "./../../images/badges/winter.svg"
// import road from "./../../images/badges/road.png"

export const foodPreferences = [
  { value: "Foodie", label: "Foodie" },
  { value: "trylocal", label: "Eat local cuisine" },
  { value: "notadventurous", label: "Not Adventurous" },
  { value: "eattosurvive", label: "Eat to survive" },
]
export const cabinClass = [
  { value: "first", label: "First" },
  { value: "business", label: "Business" },
  { value: "premium_economy", label: "Premium Economy" },
  { value: "economy", label: "Economy" },
]

export const journeyType = [
  { value: "oneway", label: "One-way" },
  { value: "roundtrip", label: "Round-trip" },
  { value: "multicity", label: "Multi-city" },
]

export const vacationType = [
  { value: "roadtrip", label: "Road Trip" },
  { value: "solotrip", label: "Solo Trip" },
  { value: "honeymoon", label: "Honeymoon" },
  { value: "familyvacay", label: "Family Vacation" },
  { value: "anniversary", label: "Anniversary" },
  { value: "romantic", label: "Romantic Trip" },
  { value: "adventure", label: "Adventure Trip" },
  { value: "other", label: "Other" },
]

export const vacationActivities = [
  {
    value: "aquarium",
    label: "Aquarium",
  },
  {
    value: "arts",
    label: "Arts & Theatre",
  },
  {
    value: "beachactivity",
    label: "Beach Activity",
  },
  {
    value: "biking",
    label: "Biking",
  },
  {
    value: "camping",
    label: "Camping",
  },
  {
    value: "coffee",
    label: "Coffee/Café",
  },
  {
    value: "concerts",
    label: "Concerts",
  },
  {
    value: "craftcocktails",
    label: "Craft Cocktails",
  },
  {
    value: "cultural",
    label: "Culture",
  },
  {
    value: "danceclub",
    label: "Dance Clubs",
  },
  {
    value: "fishing",
    label: "Fishing",
  },
  {
    value: "foodtour",
    label: "Food Tour",
  },
  {
    value: "golf",
    label: "Golf",
  },
  {
    value: "hiking",
    label: "Hiking",
  },
  {
    value: "historic",
    label: "History",
  },
  {
    value: "horseback",
    label: "Horseback Riding",
  },
  {
    value: "hotairballoon",
    label: "Hot Air Balloon",
  },
  {
    value: "breweries",
    label: "Local Breweries",
  },
  {
    value: "localclasses",
    label: "Local Classes",
  },
  {
    value: "localdistilleries",
    label: "Local Distilleries",
  },
  {
    value: "localeats",
    label: "Local Eats",
  },
  {
    value: "localtours",
    label: "Local Tours",
  },
  {
    value: "winery",
    label: "Local Wineries",
  },
  {
    value: "museums",
    label: "Museums",
  },
  {
    value: "touristspots",
    label: "Must See Tourist Spots",
  },
  {
    value: "offroading",
    label: "Off-Roading",
  },
  {
    value: "pubcrawl",
    label: "Pub Crawl",
  },
  {
    value: "rockclimbing",
    label: "Rock Climbing",
  },
  {
    value: "safari",
    label: "Safari",
  },
  {
    value: "scuba",
    label: "Scuba Diving",
  },
  {
    value: "shopping",
    label: "Shopping",
  },
  {
    value: "ski",
    label: "Skiing",
  },
  {
    value: "skydiving",
    label: "Skydiving",
  },
  {
    value: "snowboard",
    label: "Snowboarding",
  },
  {
    value: "spa",
    label: "Spas",
  },
  { value: "speakeasy", label: "Speakeasy Bars" },
  {
    value: "surfing",
    label: "Surfing",
  },
  { value: "whiskeybars", label: "Whiskey Bars" },
  { value: "rafting", label: "White Water Rafting" },
  { value: "zipline", label: "Zip Lines" },
  {
    value: "zoo",
    label: "Zoo & Animal Encounters",
  },
]

export const designerSkills = [
  {
    value: "familyfun",
    label: "Family Fun",
    description:
      "kid-friendly destinations and attractions for the ultimate family vacay",
    quote: "It's never too early to begin exploring the world",
    icon: "fas fa-baby",
    //src: family,
  },
  {
    value: "winelover",
    label: "Wine Lover",
    description: "wines and vineyards",
    quote: "Drink wine and be fine",
    icon: "fas fa-wine-glass-alt",
    //src: wine,
  },
  {
    value: "whiskeylover",
    label: "Whiskey Connoisseur",
    description: "whiskies and whiskey bars",
    quote: "",
    icon: "fas fa-glass-whiskey-rocks",
    //src: wine,
  },
  {
    value: "beerlover",
    label: "Beer Enthusiast",
    description: "beers and breweries",
    quote: "I have never met a beer I didn't like!",
    icon: "far fa-beer",
    //src: beer,
  },
  {
    value: "foodie",
    label: "Foodie",
    description: "most authentic & delicious cuisine the world has to offer",
    quote: "You can't live a full life on an empty stomach",
    //src: foodie,
    // icon: "far fa-burger-soda",
    icon: "fal fa-utensils",
  },
  {
    value: "camping",
    label: "Camping Guru",
    description: "great camping sites",
    quote: "Camp more worry less",
    icon: "far fa-campfire",
    //src: camping,
  },
  {
    value: "roadtrips",
    label: "Road trip specialist",
    description: "ins and outs of planning a successful road trip",
    quote: "Life is a highway!",
    // icon: "far fa-route",
    icon: "far fa-road",
    //src: road,
  },
  {
    value: "adrenalinejunkie",
    label: "Adrenaline Junkie",
    description:
      "exciting and adventurous activities that will get your adrenaline pumping",
    quote: "What is life without a little risk?",
    icon: "far fa-bolt",
    //src: adrenaline,
  },
  {
    value: "sports",
    label: "Sports Fanatic",
    description: "game or activity that will satisfy every type of sports fan",
    quote: "It's always a good day for sports",
    icon: "far fa-basketball-ball",
    //src: sports,
  },
  {
    value: "historybuff",
    label: "History Buff",
    description: "museums and landmarks that you need to visit",
    quote:
      "The more you know about the past, the better prepared you are for the future",
    icon: "far fa-landmark-alt",
    //src: history,
  },
  {
    value: "hiking",
    label: "Hiking/Trails Guide",
    description: "beautiful hikes and trails",
    quote: "Hiking is the answer. Who cares what the question is",
    icon: "far fa-hiking",
    //src: hike,
  },
  {
    value: "clubbing",
    label: "Clubbing Enthusiast",
    description: "most exclusive and exciting nightclubs",
    quote: "A little party never hurt anybody",
    icon: "far fa-cocktail",
    //src: club,
  },
  {
    value: "arts",
    label: "Art Connoisseur",
    description:
      "local galleries and installations that will delight your creative spirit",
    quote: "Art speaks where words are unable to explain",
    icon: "far fa-paint-brush",
    //src: art,
  },
  {
    value: "handicap",
    label: "Handicap Access Guide",
    description:
      "locations that are handicap accessible so you can have a fun and stress-free getaway",
    quote: "Accessibility allows us to tap into everyone's potential",
    icon: "fab fa-accessible-icon",
    //src: handicap,
  },
  {
    value: "offroading",
    label: "Off-Roading Navigator",
    description:
      "hidden gems to visit that can only be reached by off-road vehicles",
    quote: "Buckle up, it's going to be a bumpy ride!",
    icon: "far fa-tire-rugged",
    //src: offroad,
  },
  {
    value: "coffee",
    label: "Coffee Lover",
    description: "cafes and coffee shops",
    quote: "Coffee is my love language!",
    icon: "far fa-coffee",
    //src: coffee,
  },
  {
    value: "solo",
    label: "Solo Travel Expert",
    description:
      "destinations for solo travellers to ensure a safe and fun trip",
    quote: "Go solo, go far!",
    // icon: "far fa-smile",
    icon: "far fa-backpack",
    //src: solo,
  },
  {
    value: "animal",
    label: "Wildlife Expert",
    description: "",
    quote: "",
    icon: "far fa-paw",
    //src: solo,
  },
  {
    value: "winter",
    label: "Winter Sports Expert",
    description: "skiing and all winter sports",
    quote: "",
    icon: "far fa-skiing",
    //src: solo,
  },
  {
    value: "water",
    label: "Water Sports Expert",
    description: "snorkeling and all water sports",
    quote: "",
    icon: "far fa-swimmer",
    //src: solo,
  },
  {
    value: "climbing",
    label: "Climbing Enthusiast",
    description: "rock climbing places",
    quote: "",
    icon: "far fa-mountain",
    //src: solo,
  },

  // { value: "yoga", label: "Yoga" },
]

export const vacationPacing = [
  { value: "relaxed", label: "Relaxed" },
  { value: "balanced", label: "Balanced" },
  { value: "asmuchaspossible", label: "Cover as much as possible" },
]

export const dietaryPreferences = [
  { value: "vegan", label: "Vegan" },
  { value: "vegetarian", label: "Vegetarian" },
  { value: "glutenfree", label: "Gluten Free" },
  { value: "none", label: "None" },
]

export const activityType = [
  { value: "Aquarium", label: "Aquarium" },
  { value: "restaurant", label: "Restaurant" },
  // { value: "lunch", label: "Lunch" },
  // { value: "dinner", label: "Dinner" },
  // { value: "snack", label: "Snack" },

  { value: "lake", label: "Lake" },
  { value: "river", label: "River" },
  { value: "suspension bridge", label: "Suspension Bridge" },
  { value: "Bridge", label: "Bridge" },
  { value: "Botanical Garden", label: "Botanical Garden" },
  { value: "Cafe", label: "Cafe" },
  { value: "castle", label: "Castle" },
  { value: "Cathedral", label: "Cathedral" },
  { value: "Cemetery", label: "Cemetery" },
  { value: "coffee", label: "Coffee Shop" },
  { value: "Concert Hall", label: "Concert Hall" },

  { value: "bar", label: "Bar" },
  { value: "Beach", label: "Beach" },
  { value: "brewery", label: "Brewery" },
  { value: "distillery", label: "Distillery" },
  { value: "sightseeing", label: "Sightseeing" },
  { value: "Scenic Route", label: "Scenic Route" },
  { value: "Scenic Spot", label: "Scenic Spot" },
  { value: "shopping", label: "Shopping" },
  { value: "Stadium", label: "Stadium" },
  { value: "souvenir", label: "Souvenir" },
  { value: "Farm", label: "Farm" },
  { value: "Farmer's Market", label: "Farmer's Market" },
  { value: "Foodie Tour", label: "Foodie Tour" },
  { value: "Funicular", label: "Funicular" },

  // { value: "localtasting", label: "Food Tasting" },
  { value: "Culture Walk", label: "Culture Walk" },
  { value: "Photo Shoot", label: "Photo Shoot" },
  { value: "Park", label: "Park" },
  { value: "Palace", label: "Palace" },
  { value: "Bar Crawl", label: "Bar Crawl" },
  { value: "River Cruise", label: "River Cruise" },
  { value: "Theatre", label: "Theatre" },
  { value: "Theme Park", label: "Theme Park" },
  { value: "Library", label: "Library" },
  { value: "Local Experience", label: "Local Experience" },
  { value: "Market", label: "Market" },
  { value: "Museum", label: "Museum" },
  { value: "National Park", label: "National Park" },
  { value: "Memorial Park", label: "Memorial Park" },
  { value: "Monastery", label: "Monastery" },
  { value: "mountain peak", label: "Mountain Peak" },

  { value: "Landmark", label: "Landmark" },
  { value: "Cooking Class", label: "Cooking Class" },
  { value: "Outdoor Activity", label: "Outdoor Activity" },
  { value: "Opera House", label: "Opera House" },
  { value: "Guided Tour", label: "Guided Tour" },
  { value: "Historic Site", label: "Historic Site" },
  { value: "Hiking Trail", label: "Hiking Trail" },
  { value: "Other", label: "Other" },
  { value: "Transportation", label: "Transportation" },
  { value: "Accommodation", label: "Accommodation" },
  { value: "University", label: "University" },
  { value: "Viewpoint", label: "Viewpoint" },
  { value: "Waypoint", label: "Waypoint" },
  { value: "Waterfall", label: "Waterfall" },
  { value: "Water Body", label: "Water Body" },
  { value: "Zoo", label: "Zoo" },

  // { value: "rest/downtime", label: "Rest/Downtime" },
]
export const commuteMode = [
  { value: "walking", label: "Walking" },
  { value: "driving", label: "Driving" },
  { value: "transit", label: "Transit" },
  { value: "bicycling", label: "Bicycling" },
]
// export const activityType = [
//   { value: "breakfast", label: "Breakfast" },
//   { value: "lunch", label: "Lunch" },
//   { value: "dinner", label: "Dinner" },
//   { value: "snack", label: "Snack" },
//   { value: "coffee", label: "Coffee" },
//   { value: "bar", label: "Bar" },
//   { value: "brewery", label: "Brewery" },
//   { value: "sightseeing", label: "Sightseeing" },
//   { value: "shopping", label: "Shopping" },
//   { value: "souvenir", label: "Souvenir" },
// ]

export const durationHours = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
]

export const durationMinutes = [
  { value: "0", label: "0" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "40" },
  { value: "55", label: "45" },
]

export const tripPurpose = [
  { value: "vacation", label: "Vacation" },
  { value: "business", label: "Business" },
  // { value: "asmuchaspossible", label: "Cover as much as possible" },
]

export const accommodationPreference = [
  { value: "hotel", label: "Hotel" },
  { value: "vacationhomes", label: "Vacation Home (airbnb, Vrbo etc.)" },
  // { value: "notrequired", label: "Accommodation suggestions not required" },
  // { value: "hostel", label: "Hostel" },
  // { value: "asmuchaspossible", label: "Cover as much as possible" },
]

export const alcoholPreferences = [
  { value: "dontdrink", label: "I don't drink" },
  { value: "localwines", label: "Local Wines" },
  { value: "localcraftbeers", label: "Local Craft Beers" },
  { value: "whiskeybars", label: "Whiskey Bars" },
  { value: "speakeasy", label: "Speakeasy" },
  { value: "craftcocktails", label: "Craft Cocktails" },
  { value: "nothingspecific", label: "Nothing Specific" },
]

export const cuisines = [
  { value: "albanian", label: "Albanian" },
  { value: "argentine", label: "Argentine" },
  { value: "andhra", label: "Andhra" },
  { value: "anglo-indian", label: "Anglo-Indian" },
  { value: "arab", label: "Arab" },
  { value: "armenian", label: "Armenian" },
  { value: "assyrian", label: "Assyrian" },
  { value: "awadhi", label: "Awadhi" },
  { value: "azerbaijani", label: "Azerbaijani" },
  { value: "balochi", label: "Balochi" },
  { value: "belarusian", label: "Belarusian" },
  { value: "bangladeshi", label: "Bangladeshi" },
  { value: "bengali", label: "Bengali" },
  { value: "berber", label: "Berber" },
  { value: "brazilian", label: "Brazilian" },
  { value: "buddhist", label: "Buddhist" },
  { value: "bulgarian", label: "Bulgarian" },
  { value: "cajun", label: "Cajun" },
  { value: "cantonese", label: "Cantonese" },
  { value: "caribbean", label: "Caribbean" },
  { value: "chechen", label: "Chechen" },
  { value: "chinese", label: "Chinese" },
  { value: "chineseislamic", label: "Chinese Islamic" },
  { value: "circassian", label: "Circassian" },
  { value: "crimeantatar", label: "Crimean Tatar" },
  { value: "cypriot", label: "Cyclearpriot" },
  { value: "danish", label: "Danish" },
  { value: "english", label: "English" },
  { value: "estonian", label: "Estonian" },
  { value: "french", label: "French" },
  { value: "filipino", label: "Filipino" },
  { value: "georgian", label: "Georgian" },
  { value: "german", label: "German" },
  { value: "goan", label: "Goan" },
  { value: "goancatholic", label: "Goan Catholic" },
  { value: "greek", label: "Greek" },
  { value: "gujarati", label: "Gujarati" },
  { value: "hyderabad", label: "Hyderabad" },
  { value: "indian", label: "Indian" },
  { value: "indianchinese", label: "Indian Chinese" },
  { value: "indiansingaporean", label: "Indian Singaporean" },
  { value: "indonesian", label: "Indonesian" },
  { value: "inuit", label: "Inuit" },
  { value: "irish", label: "Irish" },
  { value: "italianamerican", label: "Italian-American" },
  { value: "italian", label: "Italian" },
  { value: "jamaican", label: "Jamaican" },
  { value: "japanese", label: "Japanese" },
  { value: "jewish", label: "Jewish" },
  { value: "karnataka", label: "Karnataka" },
  { value: "kazakh", label: "Kazakh" },
  { value: "keralite", label: "Keralite" },
  { value: "korean", label: "Korean" },
  { value: "kurdish", label: "Kurdish" },
  { value: "laotian", label: "Laotian" },
  { value: "lebanese", label: "Lebanese" },
  { value: "latvian", label: "Latvian" },
  { value: "lithuanian", label: "Lithuanian" },
  { value: "louisianacreole", label: "Louisiana Creole" },
  { value: "maharashtrian", label: "Maharashtrian" },
  { value: "mangalorean", label: "Mangalorean" },
  { value: "malay", label: "Malay" },
  { value: "malaysianchinese", label: "Malaysian Chinese" },
  { value: "malaysianindian", label: "Malaysian Indian" },
  { value: "mediterranean", label: "Mediterranean" },
  { value: "mexican", label: "Mexican" },
  { value: "mordovian", label: "Mordovian" },
  { value: "moroccan", label: "Moroccan" },
  { value: "mughal", label: "Mughal" },
  { value: "nativeamerican", label: "Native American" },
  { value: "nepalese", label: "Nepalese" },
  { value: "newmexican", label: "New Mexican" },
  { value: "odia", label: "Odia" },
  { value: "parsi", label: "Parsi" },
  { value: "pashtun", label: "Pashtun" },
  { value: "polish", label: "Polish" },
  { value: "pennsylvaniadutch", label: "Pennsylvania Dutch" },
  { value: "pakistani", label: "Pakistani" },
  { value: "peranakan", label: "Peranakan" },
  { value: "persian", label: "Persian" },
  { value: "peruvian", label: "Peruvian" },
  { value: "portuguese", label: "Portuguese" },
  { value: "punjabi", label: "Punjabi" },
  { value: "rajasthani", label: "Rajasthani" },
  { value: "romanian", label: "Romanian" },
  { value: "russian", label: "Russian" },
  { value: "sami", label: "Sami" },
  { value: "serbian", label: "Serbian" },
  { value: "sindhi", label: "Sindhi" },
  { value: "slovak", label: "Slovak" },
  { value: "slovenian", label: "Slovenian" },
  { value: "somali", label: "Somali" },
  { value: "southindian", label: "South Indian" },
  { value: "soviet", label: "Soviet" },
  { value: "spanish", label: "Spanish" },
  { value: "srilankan", label: "Sri Lankan" },
  { value: "taiwanese", label: "Taiwanese" },
  { value: "tatar", label: "Tatar" },
  { value: "thai", label: "Thai" },
  { value: "turkish", label: "Turkish" },
  { value: "tamil", label: "Tamil" },
  { value: "udupi", label: "Udupi" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "yamal", label: "Yamal" },
  { value: "zambian", label: "Zambian" },
  { value: "zanzibar", label: "Zanzibar" },
]

export const USAStates = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District Of Columbia", label: "District Of Columbia" },
  {
    value: "Federated States Of Micronesia",
    label: "Federated States Of Micronesia",
  },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Guam", label: "Guam" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Palau", label: "Palau" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virgin Islands", label: "Virgin Islands" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
]

export const languages = [
  {
    value: "Abkhaz",
    label: "Abkhaz",
  },
  {
    value: "Afar",

    label: "Afar",
  },
  {
    value: "Afrikaans",
    label: "Afrikaans",
  },
  {
    value: "Akan",

    label: "Akan",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Amharic",
    label: "Amharic",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Aragonese",
    label: "Aragonese",
  },
  {
    value: "Armenian",
    label: "Armenian",
  },
  {
    value: "Assamese",
    label: "Assamese",
  },
  {
    value: "Avaric",
    label: "Avaric",
  },
  {
    value: "Avestan",
    label: "Avestan",
  },
  {
    value: "Aymara",
    label: "Aymara",
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani",
  },
  {
    value: "Bambara",
    label: "Bambara",
  },
  {
    value: "Bashkir",
    label: "Bashkir",
  },
  {
    value: "Basque",
    label: "Basque",
  },
  {
    value: "Belarusian",
    label: "Belarusian",
  },
  {
    value: "Bengali; Bangla",
    label: "Bengali; Bangla",
  },
  {
    value: "Bihari",
    label: "Bihari",
  },
  {
    value: "Bislama",
    label: "Bislama",
  },
  {
    value: "Bosnian",
    label: "Bosnian",
  },
  {
    value: "Breton",
    label: "Breton",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",
  },
  {
    value: "Burmese",
    label: "Burmese",
  },
  {
    value: "Catalan; Valencian",
    label: "Catalan; Valencian",
  },
  {
    value: "Chamorro",
    label: "Chamorro",
  },
  {
    value: "Chechen",
    label: "Chechen",
  },
  {
    value: "Chichewa; Chewa; Nyanja",
    label: "Chichewa; Chewa; Nyanja",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Chuvash",
    label: "Chuvash",
  },
  {
    value: "Cornish",
    label: "Cornish",
  },
  {
    value: "Corsican",
    label: "Corsican",
  },
  {
    value: "Cree",

    label: "Cree",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Divehi; Dhivehi; Maldivian;",
    label: "Divehi; Dhivehi; Maldivian;",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "Dzongkha",
    label: "Dzongkha",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Esperanto",
    label: "Esperanto",
  },
  {
    value: "Estonian",
    label: "Estonian",
  },
  {
    value: "Ewe",

    label: "Ewe",
  },
  {
    value: "Faroese",
    label: "Faroese",
  },
  {
    value: "Fijian",
    label: "Fijian",
  },
  {
    value: "Finnish",
    label: "Finnish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Fula; Fulah; Pulaar; Pular",
    label: "Fula; Fulah; Pulaar; Pular",
  },
  {
    value: "Galician",
    label: "Galician",
  },
  {
    value: "Georgian",
    label: "Georgian",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek, Modern",
    label: "Greek, Modern",
  },
  {
    value: "GuaranÃ­",
    label: "GuaranÃ­",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Haitian; Haitian Creole",
    label: "Haitian; Haitian Creole",
  },
  {
    value: "Hausa",
    label: "Hausa",
  },
  {
    value: "Hebrew (modern)",
    label: "Hebrew (modern)",
  },
  {
    value: "Herero",
    label: "Herero",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Hiri Motu",
    label: "Hiri Motu",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Interlingua",
    label: "Interlingua",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Interlingue",
    label: "Interlingue",
  },
  {
    value: "Irish",
    label: "Irish",
  },
  {
    value: "Igbo",

    label: "Igbo",
  },
  {
    value: "Inupiaq",
    label: "Inupiaq",
  },
  {
    value: "Ido",

    label: "Ido",
  },
  {
    value: "Icelandic",
    label: "Icelandic",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Inuktitut",
    label: "Inuktitut",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Kalaallisut, Greenlandic",
    label: "Kalaallisut, Greenlandic",
  },
  {
    value: "Kannada",
    label: "Kannada",
  },
  {
    value: "Kanuri",
    label: "Kanuri",
  },
  {
    value: "Kashmiri",
    label: "Kashmiri",
  },
  {
    value: "Kazakh",
    label: "Kazakh",
  },
  {
    value: "Khmer",
    label: "Khmer",
  },
  {
    value: "Kikuyu, Gikuyu",
    label: "Kikuyu, Gikuyu",
  },
  {
    value: "Kinyarwanda",
    label: "Kinyarwanda",
  },
  {
    value: "Kyrgyz",
    label: "Kyrgyz",
  },
  {
    value: "Komi",

    label: "Komi",
  },
  {
    value: "Kongo",
    label: "Kongo",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Kurdish",
    label: "Kurdish",
  },
  {
    value: "Kwanyama, Kuanyama",
    label: "Kwanyama, Kuanyama",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Luxembourgish, Letzeburgesch",
    label: "Luxembourgish, Letzeburgesch",
  },
  {
    value: "Ganda",
    label: "Ganda",
  },
  {
    value: "Limburgish, Limburgan, Limburger",
    label: "Limburgish, Limburgan, Limburger",
  },
  {
    value: "Lingala",
    label: "Lingala",
  },
  {
    value: "Lao",

    label: "Lao",
  },
  {
    value: "Lithuanian",
    label: "Lithuanian",
  },
  {
    value: "Luba-Katanga",
    label: "Luba-Katanga",
  },
  {
    value: "Latvian",
    label: "Latvian",
  },
  {
    value: "Manx",

    label: "Manx",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
  },
  {
    value: "Malagasy",
    label: "Malagasy",
  },
  {
    value: "Malay",
    label: "Malay",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Maltese",
    label: "Maltese",
  },
  {
    value: "MÄori",
    label: "MÄori",
  },
  {
    value: "Marathi (MarÄá¹­hÄ«)",
    label: "Marathi (MarÄá¹­hÄ«)",
  },
  {
    value: "Marshallese",
    label: "Marshallese",
  },
  {
    value: "Mongolian",
    label: "Mongolian",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Navajo, Navaho",
    label: "Navajo, Navaho",
  },
  {
    value: "Norwegian BokmÃ¥l",
    label: "Norwegian BokmÃ¥l",
  },
  {
    value: "North Ndebele",
    label: "North Ndebele",
  },
  {
    value: "Nepali",
    label: "Nepali",
  },
  {
    value: "Ndonga",
    label: "Ndonga",
  },
  {
    value: "Norwegian Nynorsk",
    label: "Norwegian Nynorsk",
  },
  {
    value: "Norwegian",
    label: "Norwegian",
  },
  {
    value: "Nuosu",
    label: "Nuosu",
  },
  {
    value: "South Ndebele",
    label: "South Ndebele",
  },
  {
    value: "Occitan",
    label: "Occitan",
  },
  {
    value: "Ojibwe, Ojibwa",
    label: "Ojibwe, Ojibwa",
  },
  {
    value:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    label:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  },
  {
    value: "Oromo",
    label: "Oromo",
  },
  {
    value: "Oriya",
    label: "Oriya",
  },
  {
    value: "Ossetian, Ossetic",
    label: "Ossetian, Ossetic",
  },
  {
    value: "Panjabi, Punjabi",
    label: "Panjabi, Punjabi",
  },
  {
    value: "PÄli",

    label: "PÄli",
  },
  {
    value: "Persian (Farsi)",
    label: "Persian (Farsi)",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Pashto, Pushto",
    label: "Pashto, Pushto",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Quechua",
    label: "Quechua",
  },
  {
    value: "Romansh",
    label: "Romansh",
  },
  {
    value: "Kirundi",
    label: "Kirundi",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Sanskrit",
    label: "Sanskrit",
  },
  {
    value: "Sardinian",
    label: "Sardinian",
  },
  {
    value: "Sindhi",
    label: "Sindhi",
  },
  {
    value: "Northern Sami",
    label: "Northern Sami",
  },
  {
    value: "Samoan",
    label: "Samoan",
  },
  {
    value: "Sango",
    label: "Sango",
  },
  {
    value: "Serbian",
    label: "Serbian",
  },
  {
    value: "Scottish Gaelic; Gaelic",
    label: "Scottish Gaelic; Gaelic",
  },
  {
    value: "Shona",
    label: "Shona",
  },
  {
    value: "Sinhala, Sinhalese",
    label: "Sinhala, Sinhalese",
  },
  {
    value: "Slovak",
    label: "Slovak",
  },
  {
    value: "Slovene",
    label: "Slovene",
  },
  {
    value: "Somali",
    label: "Somali",
  },
  {
    value: "Southern Sotho",
    label: "Southern Sotho",
  },
  {
    value: "South Azerbaijani",
    label: "South Azerbaijani",
  },
  {
    value: "Spanish, Castilian",
    label: "Spanish, Castilian",
  },
  {
    value: "Spanish, Latin America",
    label: "Spanish, Latin America",
  },
  {
    value: "Sundanese",
    label: "Sundanese",
  },
  {
    value: "Swahili",
    label: "Swahili",
  },
  {
    value: "Swati",
    label: "Swati",
  },
  {
    value: "Swedish",
    label: "Swedish",
  },
  {
    value: "Tamil",
    label: "Tamil",
  },
  {
    value: "Telugu",
    label: "Telugu",
  },
  {
    value: "Tajik",
    label: "Tajik",
  },
  {
    value: "Thai",

    label: "Thai",
  },
  {
    value: "Tigrinya",
    label: "Tigrinya",
  },
  {
    value: "Tibetan Standard, Tibetan, Central",
    label: "Tibetan Standard, Tibetan, Central",
  },
  {
    value: "Turkmen",
    label: "Turkmen",
  },
  {
    value: "Tagalog",
    label: "Tagalog",
  },
  {
    value: "Tswana",
    label: "Tswana",
  },
  {
    value: "Tonga (Tonga Islands)",
    label: "Tonga (Tonga Islands)",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Tsonga",
    label: "Tsonga",
  },
  {
    value: "Tatar",
    label: "Tatar",
  },
  {
    value: "Twi",

    label: "Twi",
  },
  {
    value: "Tahitian",
    label: "Tahitian",
  },
  {
    value: "Uyghur, Uighur",
    label: "Uyghur, Uighur",
  },
  {
    value: "Ukrainian",
    label: "Ukrainian",
  },
  {
    value: "Urdu",

    label: "Urdu",
  },
  {
    value: "Uzbek",
    label: "Uzbek",
  },
  {
    value: "Venda",
    label: "Venda",
  },
  {
    value: "Vietlabelse",
    label: "Vietlabelse",
  },
  {
    value: "VolapÃ¼k",
    label: "VolapÃ¼k",
  },
  {
    value: "Walloon",
    label: "Walloon",
  },
  {
    value: "Welsh",
    label: "Welsh",
  },
  {
    value: "Wolof",
    label: "Wolof",
  },
  {
    value: "Western Frisian",
    label: "Western Frisian",
  },
  {
    value: "Xhosa",
    label: "Xhosa",
  },
  {
    value: "Yiddish",
    label: "Yiddish",
  },
  {
    value: "Yoruba",
    label: "Yoruba",
  },
  {
    value: "Zhuang, Chuang",
    label: "Zhuang, Chuang",
  },
  {
    value: "Zulu",
    label: "Zulu",
  },
]

export const countryListValueLabel = [
  { value: "Aland Islands", label: "Aland Islands" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  {
    value: "Bolivia Plurinational State of",
    label: "Bolivia Plurinational State of",
  },
  {
    value: "Bonaire Sint Eustatius and Saba",
    label: "Bonaire Sint Eustatius and Saba",
  },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos Keeling Islands", label: "Cocos Keeling Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Cote Divoire", label: "Cote Divoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  {
    value: "Falkland Islands Malvinas",
    label: "Falkland Islands Malvinas",
  },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "South Korea", label: "South Korea" },
  { value: "North Korea", label: "North Korea" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao Peoples Democratic Republic",
    label: "Lao Peoples Democratic Republic",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  {
    value: "Republic of North Macedonia",
    label: "Republic of North Macedonia",
  },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Reunion", label: "Reunion" },
  { value: "Saint Barthelemy", label: "Saint Barthelemy" },
  {
    value: "Saint Helena Ascension and Tristan da Cunha",
    label: "Saint Helena Ascension and Tristan da Cunha",
  },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin French part", label: "Saint Martin French part" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten Dutch part", label: "Sint Maarten Dutch part" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States of America", label: "United States of America" },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  // { value: "USA", label: "USA" },

  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  {
    value: "Venezuela Bolivarian Republic of",
    label: "Venezuela Bolivarian Republic of",
  },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands British", label: "Virgin Islands British" },
  { value: "Virgin Islands US", label: "Virgin Islands US" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
]

export const countryCode = [
  {
    Name: "Afghanistan",
    Code: "AF",
  },
  {
    Name: "Aland Islands",
    Code: "AX",
  },
  {
    Name: "Albania",
    Code: "AL",
  },
  {
    Name: "Algeria",
    Code: "DZ",
  },
  {
    Name: "American Samoa",
    Code: "AS",
  },
  {
    Name: "Andorra",
    Code: "AD",
  },
  {
    Name: "Angola",
    Code: "AO",
  },
  {
    Name: "Anguilla",
    Code: "AI",
  },
  {
    Name: "Antarctica",
    Code: "AQ",
  },
  {
    Name: "Antigua and Barbuda",
    Code: "AG",
  },
  {
    Name: "Argentina",
    Code: "AR",
  },
  {
    Name: "Armenia",
    Code: "AM",
  },
  {
    Name: "Aruba",
    Code: "AW",
  },
  {
    Name: "Australia",
    Code: "AU",
  },
  {
    Name: "Austria",
    Code: "AT",
  },
  {
    Name: "Azerbaijan",
    Code: "AZ",
  },
  {
    Name: "Bahamas",
    Code: "BS",
  },
  {
    Name: "Bahrain",
    Code: "BH",
  },
  {
    Name: "Bangladesh",
    Code: "BD",
  },
  {
    Name: "Barbados",
    Code: "BB",
  },
  {
    Name: "Belarus",
    Code: "BY",
  },
  {
    Name: "Belgium",
    Code: "BE",
  },
  {
    Name: "Belize",
    Code: "BZ",
  },
  {
    Name: "Benin",
    Code: "BJ",
  },
  {
    Name: "Bermuda",
    Code: "BM",
  },
  {
    Name: "Bhutan",
    Code: "BT",
  },
  {
    Name: "Bolivia Plurinational State of",
    Code: "BO",
  },
  {
    Name: "Bonaire Sint Eustatius and Saba",
    Code: "BQ",
  },
  {
    Name: "Bosnia and Herzegovina",
    Code: "BA",
  },
  {
    Name: "Botswana",
    Code: "BW",
  },
  {
    Name: "Bouvet Island",
    Code: "BV",
  },
  {
    Name: "Brazil",
    Code: "BR",
  },
  {
    Name: "British Indian Ocean Territory",
    Code: "IO",
  },
  {
    Name: "Brunei Darussalam",
    Code: "BN",
  },
  {
    Name: "Bulgaria",
    Code: "BG",
  },
  {
    Name: "Burkina Faso",
    Code: "BF",
  },
  {
    Name: "Burundi",
    Code: "BI",
  },
  {
    Name: "Cambodia",
    Code: "KH",
  },
  {
    Name: "Cameroon",
    Code: "CM",
  },
  {
    Name: "Canada",
    Code: "CA",
  },
  {
    Name: "Cape Verde",
    Code: "CV",
  },
  {
    Name: "Cayman Islands",
    Code: "KY",
  },
  {
    Name: "Central African Republic",
    Code: "CF",
  },
  {
    Name: "Chad",
    Code: "TD",
  },
  {
    Name: "Chile",
    Code: "CL",
  },
  {
    Name: "China",
    Code: "CN",
  },
  {
    Name: "Christmas Island",
    Code: "CX",
  },
  {
    Name: "Cocos Keeling Islands",
    Code: "CC",
  },
  {
    Name: "Colombia",
    Code: "CO",
  },
  {
    Name: "Comoros",
    Code: "KM",
  },
  {
    Name: "Congo",
    Code: "CG",
  },
  {
    Name: "Congo the Democratic Republic of the",
    Code: "CD",
  },
  {
    Name: "Cook Islands",
    Code: "CK",
  },
  {
    Name: "Costa Rica",
    Code: "CR",
  },
  {
    Name: "Cote Divoire",
    Code: "CI",
  },
  {
    Name: "Croatia",
    Code: "HR",
  },
  {
    Name: "Cuba",
    Code: "CU",
  },
  {
    Name: "Curaçao",
    Code: "CW",
  },
  {
    Name: "Cyprus",
    Code: "CY",
  },
  {
    Name: "Czech Republic",
    Code: "CZ",
  },
  {
    Name: "Czechia",
    Code: "CZ",
  },
  {
    Name: "Denmark",
    Code: "DK",
  },
  {
    Name: "Djibouti",
    Code: "DJ",
  },
  {
    Name: "Dominica",
    Code: "DM",
  },
  {
    Name: "Dominican Republic",
    Code: "DO",
  },
  {
    Name: "Ecuador",
    Code: "EC",
  },
  {
    Name: "Egypt",
    Code: "EG",
  },
  {
    Name: "El Salvador",
    Code: "SV",
  },
  {
    Name: "Equatorial Guinea",
    Code: "GQ",
  },
  {
    Name: "Eritrea",
    Code: "ER",
  },
  {
    Name: "Estonia",
    Code: "EE",
  },
  {
    Name: "Ethiopia",
    Code: "ET",
  },
  {
    Name: "Falkland Islands Malvinas",
    Code: "FK",
  },
  {
    Name: "Faroe Islands",
    Code: "FO",
  },
  {
    Name: "Fiji",
    Code: "FJ",
  },
  {
    Name: "Finland",
    Code: "FI",
  },
  {
    Name: "France",
    Code: "FR",
  },
  {
    Name: "French Guiana",
    Code: "GF",
  },
  {
    Name: "French Polynesia",
    Code: "PF",
  },
  {
    Name: "French Southern Territories",
    Code: "TF",
  },
  {
    Name: "Gabon",
    Code: "GA",
  },
  {
    Name: "Gambia",
    Code: "GM",
  },
  {
    Name: "Georgia",
    Code: "GE",
  },
  {
    Name: "Germany",
    Code: "DE",
  },
  {
    Name: "Ghana",
    Code: "GH",
  },
  {
    Name: "Gibraltar",
    Code: "GI",
  },
  {
    Name: "Greece",
    Code: "GR",
  },
  {
    Name: "Greenland",
    Code: "GL",
  },
  {
    Name: "Grenada",
    Code: "GD",
  },
  {
    Name: "Guadeloupe",
    Code: "GP",
  },
  {
    Name: "Guam",
    Code: "GU",
  },
  {
    Name: "Guatemala",
    Code: "GT",
  },
  {
    Name: "Guernsey",
    Code: "GG",
  },
  {
    Name: "Guinea",
    Code: "GN",
  },
  {
    Name: "Guinea-Bissau",
    Code: "GW",
  },
  {
    Name: "Guyana",
    Code: "GY",
  },
  {
    Name: "Haiti",
    Code: "HT",
  },
  {
    Name: "Heard Island and McDonald Islands",
    Code: "HM",
  },
  {
    Name: "Holy See Vatican City State",
    Code: "VA",
  },
  {
    Name: "Honduras",
    Code: "HN",
  },
  {
    Name: "Hong Kong",
    Code: "HK",
  },
  {
    Name: "Hungary",
    Code: "HU",
  },
  {
    Name: "Iceland",
    Code: "IS",
  },
  {
    Name: "India",
    Code: "IN",
  },
  {
    Name: "Indonesia",
    Code: "ID",
  },
  {
    Name: "Iran Islamic Republic of",
    Code: "IR",
  },
  {
    Name: "Iraq",
    Code: "IQ",
  },
  {
    Name: "Ireland",
    Code: "IE",
  },
  {
    Name: "Isle of Man",
    Code: "IM",
  },
  {
    Name: "Israel",
    Code: "IL",
  },
  {
    Name: "Italy",
    Code: "IT",
  },
  {
    Name: "Jamaica",
    Code: "JM",
  },
  {
    Name: "Japan",
    Code: "JP",
  },
  {
    Name: "Jersey",
    Code: "JE",
  },
  {
    Name: "Jordan",
    Code: "JO",
  },
  {
    Name: "Kazakhstan",
    Code: "KZ",
  },
  {
    Name: "Kenya",
    Code: "KE",
  },
  {
    Name: "Kiribati",
    Code: "KI",
  },
  {
    Name: "Korea Democratic People's Republic of",
    Code: "KP",
  },
  {
    Name: "Korea Republic of",
    Code: "KR",
  },
  {
    Name: "Kuwait",
    Code: "KW",
  },
  {
    Name: "Kyrgyzstan",
    Code: "KG",
  },
  {
    Name: "Lao Peoples Democratic Republic",
    Code: "LA",
  },
  {
    Name: "Latvia",
    Code: "LV",
  },
  {
    Name: "Lebanon",
    Code: "LB",
  },
  {
    Name: "Lesotho",
    Code: "LS",
  },
  {
    Name: "Liberia",
    Code: "LR",
  },
  {
    Name: "Libya",
    Code: "LY",
  },
  {
    Name: "Liechtenstein",
    Code: "LI",
  },
  {
    Name: "Lithuania",
    Code: "LT",
  },
  {
    Name: "Luxembourg",
    Code: "LU",
  },
  {
    Name: "Macao",
    Code: "MO",
  },
  {
    Name: "Macedonia the Former Yugoslav Republic of",
    Code: "MK",
  },
  {
    Name: "Madagascar",
    Code: "MG",
  },
  {
    Name: "Malawi",
    Code: "MW",
  },
  {
    Name: "Malaysia",
    Code: "MY",
  },
  {
    Name: "Maldives",
    Code: "MV",
  },
  {
    Name: "Mali",
    Code: "ML",
  },
  {
    Name: "Malta",
    Code: "MT",
  },
  {
    Name: "Marshall Islands",
    Code: "MH",
  },
  {
    Name: "Martinique",
    Code: "MQ",
  },
  {
    Name: "Mauritania",
    Code: "MR",
  },
  {
    Name: "Mauritius",
    Code: "MU",
  },
  {
    Name: "Mayotte",
    Code: "YT",
  },
  {
    Name: "Mexico",
    Code: "MX",
  },
  {
    Name: "Micronesia Federated States of",
    Code: "FM",
  },
  {
    Name: "Moldova Republic of",
    Code: "MD",
  },
  {
    Name: "Monaco",
    Code: "MC",
  },
  {
    Name: "Mongolia",
    Code: "MN",
  },
  {
    Name: "Montenegro",
    Code: "ME",
  },
  {
    Name: "Montserrat",
    Code: "MS",
  },
  {
    Name: "Morocco",
    Code: "MA",
  },
  {
    Name: "Mozambique",
    Code: "MZ",
  },
  {
    Name: "Myanmar",
    Code: "MM",
  },
  {
    Name: "Namibia",
    Code: "NA",
  },
  {
    Name: "Nauru",
    Code: "NR",
  },
  {
    Name: "Nepal",
    Code: "NP",
  },
  {
    Name: "Netherlands",
    Code: "NL",
  },
  {
    Name: "New Caledonia",
    Code: "NC",
  },
  {
    Name: "New Zealand",
    Code: "NZ",
  },
  {
    Name: "Nicaragua",
    Code: "NI",
  },
  {
    Name: "Niger",
    Code: "NE",
  },
  {
    Name: "Nigeria",
    Code: "NG",
  },
  {
    Name: "Niue",
    Code: "NU",
  },
  {
    Name: "Norfolk Island",
    Code: "NF",
  },
  {
    Name: "Northern Mariana Islands",
    Code: "MP",
  },
  {
    Name: "Norway",
    Code: "NO",
  },
  {
    Name: "Oman",
    Code: "OM",
  },
  {
    Name: "Pakistan",
    Code: "PK",
  },
  {
    Name: "Palau",
    Code: "PW",
  },
  {
    Name: "Palestine State of",
    Code: "PS",
  },
  {
    Name: "Panama",
    Code: "PA",
  },
  {
    Name: "Papua New Guinea",
    Code: "PG",
  },
  {
    Name: "Paraguay",
    Code: "PY",
  },
  {
    Name: "Peru",
    Code: "PE",
  },
  {
    Name: "Philippines",
    Code: "PH",
  },
  {
    Name: "Pitcairn",
    Code: "PN",
  },
  {
    Name: "Poland",
    Code: "PL",
  },
  {
    Name: "Portugal",
    Code: "PT",
  },
  {
    Name: "Puerto Rico",
    Code: "PR",
  },
  {
    Name: "Qatar",
    Code: "QA",
  },
  {
    Name: "Reunion",
    Code: "RE",
  },
  {
    Name: "Romania",
    Code: "RO",
  },
  {
    Name: "Russian Federation",
    Code: "RU",
  },
  {
    Name: "Rwanda",
    Code: "RW",
  },
  {
    Name: "Saint Barthelemy",
    Code: "BL",
  },
  {
    Name: "Saint Helena Ascension and Tristan da Cunha",
    Code: "SH",
  },
  {
    Name: "Saint Kitts and Nevis",
    Code: "KN",
  },
  {
    Name: "Saint Lucia",
    Code: "LC",
  },
  {
    Name: "Saint Martin French part",
    Code: "MF",
  },
  {
    Name: "Saint Pierre and Miquelon",
    Code: "PM",
  },
  {
    Name: "Saint Vincent and the Grenadines",
    Code: "VC",
  },
  {
    Name: "Samoa",
    Code: "WS",
  },
  {
    Name: "San Marino",
    Code: "SM",
  },
  {
    Name: "Sao Tome and Principe",
    Code: "ST",
  },
  {
    Name: "Saudi Arabia",
    Code: "SA",
  },
  {
    Name: "Senegal",
    Code: "SN",
  },
  {
    Name: "Serbia",
    Code: "RS",
  },
  {
    Name: "Seychelles",
    Code: "SC",
  },
  {
    Name: "Sierra Leone",
    Code: "SL",
  },
  {
    Name: "Singapore",
    Code: "SG",
  },
  {
    Name: "Sint Maarten Dutch part",
    Code: "SX",
  },
  {
    Name: "Slovakia",
    Code: "SK",
  },
  {
    Name: "Slovenia",
    Code: "SI",
  },
  {
    Name: "Solomon Islands",
    Code: "SB",
  },
  {
    Name: "Somalia",
    Code: "SO",
  },
  {
    Name: "South Korea",
    Code: "KR",
  },
  {
    Name: "South Africa",
    Code: "ZA",
  },
  {
    Name: "South Georgia and the South Sandwich Islands",
    Code: "GS",
  },
  {
    Name: "South Sudan",
    Code: "SS",
  },
  {
    Name: "Spain",
    Code: "ES",
  },
  {
    Name: "Sri Lanka",
    Code: "LK",
  },
  {
    Name: "Sudan",
    Code: "SD",
  },
  {
    Name: "Suriname",
    Code: "SR",
  },
  {
    Name: "Svalbard and Jan Mayen",
    Code: "SJ",
  },
  {
    Name: "Swaziland",
    Code: "SZ",
  },
  {
    Name: "Sweden",
    Code: "SE",
  },
  {
    Name: "Switzerland",
    Code: "CH",
  },
  {
    Name: "Syrian Arab Republic",
    Code: "SY",
  },
  {
    Name: "Taiwan Province of China",
    Code: "TW",
  },
  {
    Name: "Taiwan",
    Code: "TW",
  },
  {
    Name: "Tajikistan",
    Code: "TJ",
  },
  {
    Name: "Tanzania",
    Code: "TZ",
  },
  {
    Name: "Thailand",
    Code: "TH",
  },
  {
    Name: "Timor-Leste",
    Code: "TL",
  },
  {
    Name: "Togo",
    Code: "TG",
  },
  {
    Name: "Tokelau",
    Code: "TK",
  },
  {
    Name: "Tonga",
    Code: "TO",
  },
  {
    Name: "Trinidad and Tobago",
    Code: "TT",
  },
  {
    Name: "Tunisia",
    Code: "TN",
  },
  {
    Name: "Turkey",
    Code: "TR",
  },
  {
    Name: "Turkmenistan",
    Code: "TM",
  },
  {
    Name: "Turks and Caicos Islands",
    Code: "TC",
  },
  {
    Name: "Tuvalu",
    Code: "TV",
  },
  {
    Name: "Uganda",
    Code: "UG",
  },
  {
    Name: "Ukraine",
    Code: "UA",
  },
  {
    Name: "United Arab Emirates",
    Code: "AE",
  },
  {
    Name: "United Kingdom",
    Code: "GB",
  },
  {
    Name: "United States",
    Code: "US",
  },
  {
    Name: "United States Minor Outlying Islands",
    Code: "UM",
  },
  {
    Name: "Uruguay",
    Code: "UY",
  },
  {
    Name: "Uzbekistan",
    Code: "UZ",
  },
  {
    Name: "Vanuatu",
    Code: "VU",
  },
  {
    Name: "Venezuela, Bolivarian Republic of",
    Code: "VE",
  },
  {
    Name: "Vietnam",
    Code: "VN",
  },
  {
    Name: "Virgin Islands, British",
    Code: "VG",
  },
  {
    Name: "Virgin Islands British",
    Code: "VG",
  },
  {
    Name: "Virgin Islands, US",
    Code: "VI",
  },
  {
    Name: "Wallis and Futuna",
    Code: "WF",
  },
  {
    Name: "Western Sahara",
    Code: "EH",
  },
  {
    Name: "Yemen",
    Code: "YE",
  },
  {
    Name: "Zambia",
    Code: "ZM",
  },
  {
    Name: "Zimbabwe",
    Code: "ZW",
  },
  {
    Name: "USA",
    Code: "US",
  },
  {
    Name: "United States of America",
    Code: "US",
  },
  {
    Name: "Palestine",
    Code: "PS",
  },
  {
    Name: "Holy See",
    Code: "VA",
  },
]

export const months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
]

export const days = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
]
export const years = [
  { value: "1920", label: "1920" },
  { value: "1921", label: "1921" },
  { value: "1922", label: "1922" },
  { value: "1923", label: "1923" },
  { value: "1924", label: "1924" },
  { value: "1925", label: "1925" },
  { value: "1926", label: "1926" },
  { value: "1927", label: "1927" },
  { value: "1928", label: "1928" },
  { value: "1929", label: "1929" },
  { value: "1930", label: "1930" },

  { value: "1931", label: "1921" },
  { value: "1932", label: "1922" },
  { value: "1933", label: "1923" },
  { value: "1934", label: "1924" },
  { value: "1935", label: "1925" },
  { value: "1936", label: "1926" },
  { value: "1937", label: "1927" },
  { value: "1938", label: "1928" },
  { value: "1939", label: "1929" },

  { value: "1940", label: "1940" },
  { value: "1941", label: "1941" },
  { value: "1942", label: "1942" },
  { value: "1943", label: "1943" },
  { value: "1944", label: "1944" },
  { value: "1945", label: "1945" },
  { value: "1946", label: "1946" },
  { value: "1947", label: "1947" },
  { value: "1948", label: "1948" },
  { value: "1949", label: "1949" },

  { value: "1950", label: "1950" },
  { value: "1951", label: "1951" },
  { value: "1952", label: "1952" },
  { value: "1953", label: "1953" },
  { value: "1954", label: "1954" },
  { value: "1955", label: "1955" },
  { value: "1956", label: "1956" },
  { value: "1957", label: "1957" },
  { value: "1958", label: "1958" },
  { value: "1959", label: "1959" },

  { value: "1960", label: "1960" },
  { value: "1961", label: "1961" },
  { value: "1962", label: "1962" },
  { value: "1963", label: "1963" },
  { value: "1964", label: "1964" },
  { value: "1965", label: "1965" },
  { value: "1966", label: "1966" },
  { value: "1967", label: "1967" },
  { value: "1968", label: "1968" },
  { value: "1969", label: "1969" },

  { value: "1970", label: "1970" },
  { value: "1971", label: "1971" },
  { value: "1972", label: "1972" },
  { value: "1973", label: "1973" },
  { value: "1974", label: "1974" },
  { value: "1975", label: "1975" },
  { value: "1976", label: "1976" },
  { value: "1977", label: "1977" },
  { value: "1978", label: "1978" },
  { value: "1979", label: "1979" },

  { value: "1980", label: "1980" },
  { value: "1981", label: "1981" },
  { value: "1982", label: "1982" },
  { value: "1983", label: "1983" },
  { value: "1984", label: "1984" },
  { value: "1985", label: "1985" },
  { value: "1986", label: "1986" },
  { value: "1987", label: "1987" },
  { value: "1988", label: "1988" },
  { value: "1989", label: "1989" },

  { value: "1990", label: "1990" },
  { value: "1991", label: "1991" },
  { value: "1992", label: "1992" },
  { value: "1993", label: "1993" },
  { value: "1994", label: "1994" },
  { value: "1995", label: "1995" },
  { value: "1996", label: "1996" },
  { value: "1997", label: "1997" },
  { value: "1998", label: "1998" },
  { value: "1999", label: "1999" },

  { value: "2000", label: "2000" },
  { value: "2001", label: "2001" },
  { value: "2002", label: "2002" },
  { value: "2003", label: "2003" },
  { value: "2004", label: "2004" },
  { value: "2005", label: "2005" },
  { value: "2006", label: "2006" },
  { value: "2007", label: "2007" },
  { value: "2008", label: "2008" },
  { value: "2009", label: "2009" },

  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
]

export const tags = [
  "markets",
  "local markets",
  "flee markets",
  "souvenir",
  "street food",
  "bakeries",
  "fine dining",
  "cheap eats",
  "budget eats",
  "coffee",
  "foodie",
  "local cusine",
  "musuem",
  "museums",
  "theatre",
  "music",
  "history",
  "arts",
  "beaches",
  "dancing",
  "nightlife",
  "clubs",
  "sports",
  "family",
  "kids",
  "walking",
  "outdoors",
  "hiking",
]

export const hearAboutUs = [
  { value: "Instagram", label: "Instagram" },
  { value: "Twitter", label: "Twitter" },
  { value: "Google", label: "Google" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Other", label: "Other" },
]
