import { createContext } from "react"
import { useImmerReducer } from "use-immer"
import React from "react"
import { globalStateReducer } from "./global-state-reducer"

const defaultGlobalState = {
  currentSessionRole: "",
}

const globalStateContext = createContext(defaultGlobalState)
const dispatchGlobalStateContext = createContext(undefined)

export const useGlobalStateStore = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchGlobalStateContext),
]

export const GlobalStateProvider = ({ children }) => {
  const [globalState, globalDispatch] = useImmerReducer(
    globalStateReducer,
    defaultGlobalState
  )
  return (
    <globalStateContext.Provider value={globalState}>
      <dispatchGlobalStateContext.Provider value={globalDispatch}>
        {children}
      </dispatchGlobalStateContext.Provider>
    </globalStateContext.Provider>
  )
}
